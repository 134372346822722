@if (label) {
  <label class="form-label" [attr.for]="id" [attr.data-role]="'select-list-ui-label'">{{ label }}</label>
}
@if (filterEnabled) {
  <ng-select
    #select
    class="bb-select-list"
    bindLabel="name"
    [items]="filteredItems$ | async"
    [hideSelected]="true"
    [maxSelectedItems]="maxSelectedItems"
    [multiple]="multiple"
    [minTermLength]="minTermLength"
    [typeahead]="typeaheadInput$"
    [virtualScroll]="true"
    [placeholder]="placeholder"
    [attr.data-role]="id"
    [ngModel]="value"
    [loading]="loading"
    (ngModelChange)="onValueChange($event)"
    (search)="onSearch($event)"
    [labelForId]="id"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (clear)="clear.emit()"
    (open)="onOpen()"
  >
  </ng-select>
} @else {
  <ng-select
    #select
    class="bb-select-list"
    bindLabel="name"
    [items]="items"
    [hideSelected]="true"
    [maxSelectedItems]="maxSelectedItems"
    [multiple]="multiple"
    [minTermLength]="minTermLength"
    [virtualScroll]="true"
    [placeholder]="placeholder"
    [attr.data-role]="id"
    [ngModel]="value"
    [loading]="loading"
    (ngModelChange)="onValueChange($event)"
    (search)="onSearch($event)"
    [labelForId]="id"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (clear)="clear.emit()"
    (open)="onOpen()"
  >
  </ng-select>
}
