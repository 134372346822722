<div class="bb-mode-header">
  <div class="bb-stack bb-stack--density-md">
    @if (showCloseButton) {
      <div class="bb-stack__item">
        <button
          bbButton
          buttonSize="md"
          circle="true"
          color="primary"
          [attr.aria-label]="closeButtonAriaLabel"
          (click)="close.emit()"
          data-role="mode-header-close-button"
        >
          <bb-icon-ui name="clear"></bb-icon-ui>
        </button>
      </div>
    }
    <div class="bb-stack__item" data-role="mode-header-icon">
      <ng-content select="[mode-header-icon]"></ng-content>
    </div>
    <div
      class="bb-stack__item bb-mode-header__title-area"
      [class.bb-mode-header__title-area--reversed]="reverseTitleOrder"
    >
      <div class="bb-stack">
        <bb-header-ui
          headingType="h2"
          headingClasses="bb-text--mode-header"
          [heading]="title"
          data-role="mode-header-primary-header"
          class="bb-stack__item"
        ></bb-header-ui>
        <div class="bb-stack__item" data-role="mode-header-status">
          <ng-content select="[mode-header-status]"></ng-content>
        </div>
      </div>
      @if (secondaryTitle) {
        <span class="bb-subtitle" data-role="mode-header-secondary-header">{{ secondaryTitle }}</span>
      }
    </div>
    <div class="bb-stack__item bb-stack__item--push-right" data-role="mode-header-utility-area">
      <div class="bb-button-bar bb-button-bar--reverse">
        <ng-content select="[mode-header-utility-area]"></ng-content>
      </div>
    </div>
  </div>
</div>
