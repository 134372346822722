import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';

@Directive({ selector: 'ng-template[bbCustomProductItemCurrentAccountTitle]' })
export class CustomProductItemCurrentAccountTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCurrentAccountDetailsFormatter]' })
export class CustomProductItemCurrentAccountDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCurrentAccountMainSection]' })
export class CustomProductItemCurrentAccountMainSectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCurrentAccountSecondarySection]' })
export class CustomProductItemCurrentAccountSecondarySectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemCurrentAccountComponent
 *
 * @description
 * A component that renders a styled-container or card for current account.
 */
@Component({
  selector: 'bb-product-item-current-account-ui',
  templateUrl: './product-item-current-account.component.html',
})
export class ProductItemCurrentAccountComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemCurrentAccountTitleDirective, { static: true })
  customProductItemCurrentAccountTitleDirective: CustomProductItemCurrentAccountTitleDirective | undefined;

  @ContentChild(CustomProductItemCurrentAccountDetailsFormatterDirective, { static: true })
  customProductItemCurrentAccountDetailsFormatter: CustomProductItemCurrentAccountDetailsFormatterDirective | undefined;

  @ContentChild(CustomProductItemCurrentAccountMainSectionDirective, { static: true })
  customProductItemCurrentAccountMainSection: CustomProductItemCurrentAccountMainSectionDirective | undefined;

  @ContentChild(CustomProductItemCurrentAccountSecondarySectionDirective, { static: true })
  customProductItemCurrentAccountSecondarySection: CustomProductItemCurrentAccountSecondarySectionDirective | undefined;

  readonly hostRef: ProductItemCurrentAccountComponent = this;
  /**
   * The card currency.
   */
  @Input() currency: string | undefined;
  /**
   * The card amount value.
   */
  @Input() amount: number | undefined;
  /**
   * The card additional main section currency.
   */
  @Input() mainSectionCurrency: string | undefined;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionAmount: number | undefined;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionTitle: string | undefined;
  /**
   * The card additional secondary section currency.
   */
  @Input() secondarySectionCurrency: string | undefined;
  /**
   * The card additional secondary section amount value.
   */
  @Input() secondarySectionAmount: number | undefined;
  /**
   * The card additional secondary section amount value.
   */
  @Input() secondarySectionTitle: string | undefined;
}
