<span class="contextual-alternates-off" data-role="account-number">
  {{ getAccountNumber() | bbAccountNumber: getAccountNumberType() }}
</span>
@if (unmaskable) {
  @if (masked) {
    <button
      bbButton
      [triggers]="tooltipTrigger"
      [bbTooltip]="unmaskButtonTooltip"
      aria-label="Unmask"
      i18n-aria-label="Unmask button@@account-number-ui.button-unmask-label"
      (click)="onUnmask()"
      buttonSize="sm"
      circle="true"
      color="link"
      type="button"
      data-role="account-number-unmask-button"
    >
      <bb-icon-ui name="visibility"></bb-icon-ui>
    </button>
  } @else {
    <button
      bbButton
      [triggers]="tooltipTrigger"
      [bbTooltip]="maskButtonTooltip"
      aria-label="Mask"
      i18n-aria-label="Mask button@@account-number-ui.button-mask-label"
      (click)="onMask()"
      buttonSize="sm"
      circle="true"
      color="link"
      type="button"
      data-role="account-number-mask-button"
    >
      <bb-icon-ui name="visibility-off"></bb-icon-ui>
    </button>
  }
  <ng-template #unmaskButtonTooltip>
    @switch (type) {
      @case ('iban') {
        <ng-container i18n="Unmask IBAN|Button to unmask IBAN@@account-number-ui.button-unmask-iban.tooltip">
          Unmask IBAN
        </ng-container>
      }
      @case ('bban') {
        <ng-container i18n="Unmask BBAN|Button to unmask BBAN@@account-number-ui.button-unmask-bban.tooltip">
          Unmask BBAN
        </ng-container>
      }
      @case ('cardNumber') {
        <ng-container i18n="Unmask number|Button to unmask card number@@account-number-ui.button-unmask-number.tooltip">
          Unmask number
        </ng-container>
      }
      @default {
        <ng-container i18n="Unmask|Button to unmask@@account-number-ui.button-unmask.tooltip"> Unmask </ng-container>
      }
    }
  </ng-template>
  <ng-template #maskButtonTooltip>
    @switch (type) {
      @case ('iban') {
        <ng-container i18n="Mask IBAN|Button to unmask IBAN@@account-number-ui.button-mask-iban.tooltip">
          Mask IBAN
        </ng-container>
      }
      @case ('bban') {
        <ng-container i18n="Mask BBAN|Button to unmask BBAN@@account-number-ui.button-mask-bban.tooltip">
          Mask BBAN
        </ng-container>
      }
      @case ('cardNumber') {
        <ng-container i18n="Mask number|Button to unmask card number@@account-number-ui.button-mask-number.tooltip">
          Mask number
        </ng-container>
      }
      @default {
        <ng-container i18n="Mask|Button to unmask@@account-number-ui.button-mask.tooltip"> Mask </ng-container>
      }
    }
  </ng-template>
}
