import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import '@angular/localize/init';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';
import { InputDatepickerComponent } from './input-datepicker.component';
import { MinDateValidatorDirective } from './validation/min-date-validator/min-date-validator.directive';
import { MaxDateValidatorDirective } from './validation/max-date-validator/max-date-validator.directive';
import { InputDatepickerDayTemplateSingleComponent } from './input-datepicker-day-template-single.component';
import { InputDatepickerDayTemplateRangeComponent } from './input-datepicker-day-template-range.component';

export { MinDateValidatorDirective } from './validation/min-date-validator/min-date-validator.directive';
export { MaxDateValidatorDirective } from './validation/max-date-validator/max-date-validator.directive';

@NgModule({
  imports: [
    NgClass,
    AsyncPipe,
    DatePipe,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    ButtonModule,
    IconModule,
    BaseClassesModule,
  ],
  declarations: [
    InputDatepickerComponent,
    MinDateValidatorDirective,
    MaxDateValidatorDirective,
    InputDatepickerDayTemplateSingleComponent,
    InputDatepickerDayTemplateRangeComponent,
  ],
  exports: [
    InputDatepickerComponent,
    MinDateValidatorDirective,
    MaxDateValidatorDirective,

    /**
     * @deprecated
     * Exporting `FormsModule` is deprecated
     **/
    FormsModule,
  ],
})
export class InputDatepickerModule {}
