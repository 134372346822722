@if (label) {
  <label for="{{ id }}" class="form-label">{{ label }}</label>
}
<input
  #inputNumber
  [bbDigitOnly]="!allowNonDigits || allowNegativeSign"
  [allowNegativeSign]="allowNegativeSign"
  id="{{ id }}"
  [attr.aria-autocomplete]="ariaAutocomplete"
  [attr.aria-controls]="ariaControls"
  [attr.aria-labelledby]="ariaLabelledby"
  [attr.aria-describedby]="ariaDescribedby"
  [attr.aria-expanded]="ariaExpanded"
  [attr.role]="role"
  [attr.aria-invalid]="ariaInvalid"
  [attr.aria-owns]="ariaOwns"
  [attr.aria-label]="(!label && ariaLabel) || null"
  [ngModel]="value"
  (ngModelChange)="onValueChange($event)"
  (blur)="onBlur()"
  type="number"
  [inputMode]="!allowNonDigits || allowNegativeSign ? 'decimal' : 'text'"
  class="form-control"
  (focus)="onFocus()"
  (input)="onInput(inputNumber)"
  (paste)="onPaste(inputNumber)"
  [disabled]="disabled"
  [readOnly]="readonly"
  [placeholder]="placeholder"
  [required]="required"
  [attr.min]="min"
  [attr.max]="max"
  [attr.size]="size"
  [attr.step]="step"
  [bbFocus]="autofocus"
  [attr.autocomplete]="autocomplete"
  data-role="input-number"
/>
