
  import { MenuItemComponent } from './pages/_shared/menu-item-component/menu-item.component';
  import { DocShowcasePagesComponent } from './pages/_shared/doc-showcase-pages/doc-showcase-pages.component';
  import { ShowcaseComponent } from './pages/components/showcase/showcase.component';
  import { ShowcaseWebComponent } from './pages/components/showcase/web/showcase-web.component';
  import { ShowcaseDesignComponent } from './pages/components/showcase/design/showcase-design.component';
  import { MobileShowcaseComponent } from './pages/mobile/mobile-showcase/mobile-showcase.component';
  import { ShowcaseAndroidComponent } from './pages/mobile/android/showcase-android.component';
  import { ShowcaseIOSComponent } from './pages/mobile/ios/showcase-ios.component';
  import { ThemeDocComponent } from './pages/web-theme/doc-page/theme-doc.component';
  import { Routes } from '@angular/router';

  export const generatedRoutes: Routes = [{
      path: 'get-started',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'figma',
      component: MenuItemComponent
    },{
      path: 'web-',
      component: MenuItemComponent
    },{
      path: 'ios-',
      component: MenuItemComponent
    },{
      path: 'android-',
      component: MenuItemComponent
    },
    ]
    },{
      path: 'theme',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'create-a-theme',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-android',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-ios',
      component: MenuItemComponent
    },{
      path: 'apply-a-theme-on-web',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-android',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-ios',
      component: MenuItemComponent
    },{
      path: 'multi-theming-on-web',
      component: MenuItemComponent
    },
    ]
    },{
      path: 'principles',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'design-tokens',
      component: MenuItemComponent
    },{
      path: 'web-basics',
      component: MenuItemComponent
    },{
      path: 'mobile-basics',
      component: MenuItemComponent
    },{
      path: 'design-token-api',
      component: MenuItemComponent
    },
    ]
    },{
      path: 'accessibility',
      component: DocShowcasePagesComponent,
      children: [
      
      {
      path: 'overview',
      component: MenuItemComponent
    },{
      path: 'designer-checklist',
      component: MenuItemComponent
    },{
      path: 'developer-checklist',
      component: MenuItemComponent
    },
    ]
    },{
      path: 'motion-beta',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
        path: 'motion-foundation',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'hierarchy',
      component: MenuItemComponent
    },{
      path: 'anticipation',
      component: MenuItemComponent
    },{
      path: 'direction',
      component: MenuItemComponent
    },{
      path: 'easing',
      component: MenuItemComponent
    },{
      path: 'morphing',
      component: MenuItemComponent
    },{
      path: 'motion-glossary',
      component: MenuItemComponent
    },
    ]
      },{
      path: 'motion-components',
      component: MenuItemComponent
    },{
      path: 'motion-tokens',
      component: MenuItemComponent
    },{
        path: 'web-guidelines',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
      path: 'inline-layers',
      component: MenuItemComponent
    },
    ]
      },
    ]
    },{
      path: 'guides',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
        path: 'web-developers',
        children: [
      
      {
      path: 'working-with-theme',
      component: MenuItemComponent
    },{
      path: 'custom-icons',
      component: MenuItemComponent
    },
    ]
      },
    ]
    },{
      path: 'web-components',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
        path: 'badges',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'collapsibles',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'dropdowns',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'forms',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
      path: 'icon-set',
      component: MenuItemComponent
    },{
        path: 'layouts-and-navigation',
        children: [
      
      {
      path: 'horizontal',
      component: MenuItemComponent
    },{
      path: 'vertical',
      component: MenuItemComponent
    },
    ]
      },{
        path: 'loaders',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'product-items',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },{
        path: 'states',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
      },
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
    },{
      path: 'web-formatters',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'web',
    },
    {
      path: 'web',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'web-deprecated',
      component: ShowcaseWebComponent,
    },         
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    },
    {
      path: '**',
      component: ShowcaseComponent,
    },
  ]
}
    ]
    },{
      path: 'web-theme',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
        path: 'variables',
        children: [
      
      
{
  path: ':id',
  component: ThemeDocComponent
}

    ]
      },{
        path: 'components',
        children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      
{
  path: ':id',
  component: ThemeDocComponent
}

    ]
      },
    ]
    },{
      path: 'mobile-components',
      component: DocShowcasePagesComponent,
      children: [
      { path: '', pathMatch: 'full', component: MenuItemComponent },
      {
        path: 'forms',
        children: [
      
      
{
  path: ':id',
  component: MobileShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
    },
    {
      path: 'android',
      component: ShowcaseAndroidComponent,
    },
    {
      path: 'ios',
      component: ShowcaseIOSComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    }
  ],
}

    ]
      },
{
  path: ':id',
  component: MobileShowcaseComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
    },
    {
      path: 'android',
      component: ShowcaseAndroidComponent,
    },
    {
      path: 'ios',
      component: ShowcaseIOSComponent,
    },
    {
      path: 'design',
      component: ShowcaseDesignComponent,
    }
  ],
}

    ]
    }]