import { NgModule } from '@angular/core';

import {
  CustomProductItemInvestmentAccountDetailsFormatterDirective,
  CustomProductItemInvestmentAccountTitleDirective,
  ProductItemInvestmentAccountComponent,
} from './product-item-investment-account.component';
import { AmountModule } from '@backbase/ui-ang/amount';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [AmountModule, PaymentCardNumberModule, HighlightModule, IconModule],
  declarations: [
    ProductItemInvestmentAccountComponent,
    CustomProductItemInvestmentAccountTitleDirective,
    CustomProductItemInvestmentAccountDetailsFormatterDirective,
  ],
  exports: [
    ProductItemInvestmentAccountComponent,
    CustomProductItemInvestmentAccountTitleDirective,
    CustomProductItemInvestmentAccountDetailsFormatterDirective,
  ],
})
export class ProductItemInvestmentAccountModule {}
