import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { InputDateDividedComponent } from './input-date-divided.component';
import { provideNgxMask } from 'ngx-mask';

@NgModule({
  imports: [FormsModule, BaseClassesModule, ReactiveFormsModule, InputTextModule],
  declarations: [InputDateDividedComponent],
  exports: [InputDateDividedComponent],
  providers: [provideNgxMask()],
})
export class InputDateDividedModule {}
