import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent, AccountStatus } from '@backbase/ui-ang/base-classes';

@Directive({ selector: 'ng-template[bbCustomProductItemBasicAccountType]' })
export class CustomProductItemBasicAccountTypeDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemBasicAccountTitle]' })
export class CustomProductItemBasicAccountTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemBasicAccountDetailsFormatter]' })
export class CustomProductItemBasicAccountDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemBasicAccountComponent
 *
 * @description
 * A component that renders a styled-container or card for basic account.
 */
@Component({
  selector: 'bb-product-item-basic-account-ui',
  templateUrl: './product-item-basic-account.component.html',
})
export class ProductItemBasicAccountComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemBasicAccountTypeDirective, { static: true })
  customProductItemBasicAccountType: CustomProductItemBasicAccountTypeDirective | undefined;

  @ContentChild(CustomProductItemBasicAccountTitleDirective, { static: true })
  customProductItemBasicAccountTitle: CustomProductItemBasicAccountTitleDirective | undefined;

  @ContentChild(CustomProductItemBasicAccountDetailsFormatterDirective, { static: true })
  customProductItemBasicAccountDetailsFormatter: CustomProductItemBasicAccountDetailsFormatterDirective | undefined;

  readonly hostRef: ProductItemBasicAccountComponent = this;
  /**
   * The card currency.
   */
  @Input() currency: string | undefined;
  /**
   * Whether the item should show the currency symbol. Defaults to true.
   */
  @Input() showCurrencySymbol = true;
  /**
   * The card amount value.
   */
  @Input() amount: number | undefined;
  /**
   * The card secondary amount value.(e.g. Available amount is primary and booked balance is secondary).
   */
  @Input() secondaryAmount: number | undefined;
  /**
   * Whether the card is selected.
   * This removes any check mark on the item when `active=true`.
   * Defaults to false.
   */
  @Input() selected = false;
  /**
   * The type of icon to be displayed when `selected=true`.
   */
  @Input() type: string | undefined;
  /**
   * The current account status. Accepts `closed`, `inactive` or `active`.
   */
  @Input() status: AccountStatus | undefined;
  /**
   * The text to be placed above the amount.
   */
  @Input() balanceLabel: string | undefined;
  /**
   * The text to be placed above the amount.
   */
  @Input() balanceLabelForSecondaryAmount: string | undefined;
  /**
   * Branch code of a bank.
   */
  @Input() bankBranchCode: string | undefined;
}
