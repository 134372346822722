import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges, Optional } from '@angular/core';
import {
  getCurrencySymbol,
  getLocaleNumberFormat,
  getLocaleNumberSymbol,
  getNumberOfCurrencyDigits,
  NumberFormatStyle,
  NumberSymbol,
} from '@angular/common';

import BigNumber from 'bignumber.js';
import { AMOUNT_CONFIG_TOKEN, AmountConfig } from './amount.token-config';
import { CurrencyFormat, CurrencyFormatEnum } from './models/currency-format.model';
import { isRtl } from '@backbase/ui-ang/util';

interface LocaleConfiguration {
  currencyFormat: string;
  decimalFormat: string;
  percentFormat: string;
  decimalSymbol: string;
  groupSymbol: string;
  minusSymbol: string;
  plusSymbol: string;
  percentSymbol: string;
  currencyGroupSymbol: string;
  currencyDecimalSymbol: string;
}

/**
 * @name AmountComponent
 *
 * @description
 * Component to enable the amount to be represented in the currency format.
 * <br><br>
 *
 * ### Locale token
 * `LOCALE_ID` enables you to globally set the same locale for all instances of `AmountComponent` in your project.
 * Refer to https://angular.io/api/core/LOCALE_ID for more information.
 * <br><br>
 *
 * ### Global configuration token
 * `AMOUNT_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `AmountComponent` in your project.
 *
 * *Note:* The token overwrites the default value only. If you have provided a value as a property on a specific component, the token is not be able to overwrite it.
 *
 * The following properties can be overwritten using the token:
 *  - `abbreviate`
 *  - `currency`
 *  - `decimalPlaces`
 *  - `mapCurrency`
 *  - `trailingZeroes`
 *  - `currencyFormat`
 *  - `signPosition`
 *
 * The following is an example of how to use the token:
 *
 * ```typescript
 * import { AMOUNT_CONFIG_TOKEN } from '@backbase/ui-ang/amount';
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { AppModule } from './app/app.module';
 *
 * const amountConfig = {
 *   abbreviate: true
 * }
 *
 * platformBrowserDynamic().bootstrapModule(AppModule, {
 *   providers: [{ provide: AMOUNT_CONFIG_TOKEN, useValue: amountConfig }]
 * });
 * ```
 *
 * @dynamic (to suppress error with resolving statics declarations during compilation)
 *
 * @a11y
 * The amount component doesn't provide any specific properties for accessibility. But handles accessibility internally.
 *
 */
@Component({
  selector: 'bb-amount-ui',
  templateUrl: './amount.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountComponent implements OnChanges {
  private static readonly templates = {
    sign: `<span class="sign">{{}}</span>`,
    currency: `<bdi class="symbol">{{}}</bdi>`,
    integer: `<span class="integer">{{}}</span>`,
    separator: `<span class="decimal-separator">{{}}</span>`,
    decimals: `<span class="decimals">{{}}</span>`,
    percent: `<span class="percent bb-d-inline-block">{{}}</span>`,
  };
  private static readonly abbreviationConfig = {
    minAmount: 100000,
    suffixes: ['K', 'M', 'B', 'T'],
  };
  private static readonly maxSafeIntegerLength = Number.MAX_SAFE_INTEGER.toString().length;
  private static localeConfig: LocaleConfiguration;

  /**
   * Determines currency type.
   * If nothing provided, wouldn't be displayed.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input()
  get currency(): string {
    return this._currency ?? this.overrideConfig?.currency ?? '';
  }
  set currency(value: AmountConfig['currency']) {
    this._currency = value;
  }

  private _currency: AmountConfig['currency'];

  /**
   * Whether to display wide or narrow currency format.
   * Default value 'wide'.
   */
  @Input()
  get currencyFormat(): CurrencyFormat {
    return this._currencyFormat ?? this.overrideConfig?.currencyFormat ?? CurrencyFormatEnum.WIDE;
  }
  set currencyFormat(value: AmountConfig['currencyFormat']) {
    this._currencyFormat = value;
  }

  private _currencyFormat: AmountConfig['currencyFormat'];

  /**
   * If "true" currency will be hidden
   * Can be used to display amounts without currency but still format them according to the currency
   * (for example number of decimal points and groups symbol is based on a currency)
   *
   * Default value false.
   */
  @Input() hideCurrencySymbol = false;

  /**
   * If "true" and amount is positive adds plus sign at the beginning.
   * Default value false.
   */
  @Input() showPlusSign = false;

  /**
   * Whether currency local should be transformed to symbol.
   * Default value from injection token (if provided), else true.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input()
  get mapCurrency(): boolean {
    return this._mapCurrency ?? this.overrideConfig?.mapCurrency ?? true;
  }
  set mapCurrency(value: AmountConfig['mapCurrency']) {
    this._mapCurrency = value;
  }

  private _mapCurrency: AmountConfig['mapCurrency'];

  /**
   * Whether percent symbol should be shown.
   * Default value false.
   */
  @Input() showPercent = false;

  /**
   * Whether abbreviation should be applied.
   * Default value false.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input()
  get abbreviate(): boolean {
    return this._abbreviate ?? this.overrideConfig?.abbreviate ?? false;
  }
  set abbreviate(value: AmountConfig['abbreviate']) {
    this._abbreviate = value;
  }

  private _abbreviate: AmountConfig['abbreviate'];

  /**
   * Overrides amount of decimals places to display.
   * Default value undefined, which will use currency default amount of decimals.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input()
  get decimalPlaces(): number | undefined {
    return this._decimalPlaces ?? this.overrideConfig?.decimalPlaces;
  }
  set decimalPlaces(value: AmountConfig['decimalPlaces']) {
    this._decimalPlaces = value;
  }

  private _decimalPlaces: AmountConfig['decimalPlaces'];

  /**
   * Whether to display trailing zeroes.
   * Default value true.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input()
  get trailingZeroes(): boolean {
    return this._trailingZeroes ?? this.overrideConfig?.trailingZeroes ?? true;
  }
  set trailingZeroes(value: AmountConfig['trailingZeroes']) {
    this._trailingZeroes = value;
  }

  private _trailingZeroes: AmountConfig['trailingZeroes'];

  /**
   * Position of the sign, whether it is before or after the currency symbol.
   * Only applies to negative numbers.
   * "left" renders the sign before the currency.
   * "right" renders the sign after the currency.
   * Default to "left".
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input()
  get signPosition(): Required<AmountConfig['signPosition']> {
    return this._signPosition ?? this.overrideConfig?.signPosition ?? 'left';
  }
  set signPosition(value: AmountConfig['signPosition']) {
    this._signPosition = value;
  }

  private _signPosition: AmountConfig['signPosition'];

  /**
   * Receive amount in number or string format and converts into a floating-point number.
   *
   * Does not support comma separated numbers unless used with `DecimalPipe`.
   * Locale is infer from `LOCALE_ID` and does not support any locales that is different from the app's `LOCALE_ID`.
   */
  @Input() amount!: string | number;

  abbreviationSuffix?: string;
  amountTemplate?: string;

  private _amountValue = new BigNumber(NaN);
  private groupSymbol!: string;
  private decimalSymbol!: string;
  private numberFormat!: BigNumber.Format;

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    @Optional() @Inject(AMOUNT_CONFIG_TOKEN) private readonly overrideConfig: AmountConfig,
  ) {
    this.setupConfiguration();
  }

  ngOnChanges() {
    if (this.isUnsafeAmount) {
      console.warn('[bb-amount]: the amount was passed as a non safe number, the display can be wrong');
    }

    this.updateNumberFormat();

    this._amountValue = new BigNumber(this.toParsableNumber(this.amount));
    this.amountTemplate = this.getAmountTemplate(this.transformedAmount);
  }

  get isNumeric(): boolean {
    return !this._amountValue.isNaN();
  }

  private get isPositive(): boolean {
    return this._amountValue.isPositive();
  }

  private get isZero(): boolean {
    return this._amountValue.isZero();
  }

  private get isUnsafeAmount(): boolean {
    return (
      typeof this.amount === 'number' &&
      (this.amount > Number.MAX_SAFE_INTEGER ||
        this.amount.toString().replace(/\D/, '').length > AmountComponent.maxSafeIntegerLength)
    );
  }

  private get configDecimalPlaces(): number {
    return this.decimalPlaces === undefined ? getNumberOfCurrencyDigits(this.currency) : this.decimalPlaces;
  }

  /**
   * Currency format depends on locale and could be represented for negative and
   * positive values differently (separated by `;` symbol).
   * See more http://cldr.unicode.org/translation/numbers-currency/number-patterns
   *
   * Example:
   * en-NL locale: '¤ #,##0.00;¤ -#,##0.00'
   */
  private get amountFormat(): string {
    const { currencyFormat, decimalFormat, percentFormat, minusSymbol, plusSymbol } = AmountComponent.localeConfig;
    let formatPattern = decimalFormat;

    if (this.showPercent) {
      formatPattern = percentFormat;
    }

    if (this.currency && !this.hideCurrencySymbol) {
      if (this.showPercent) {
        console.warn(
          '[bb-amount]: wrong configuration, `currency` and `showPercent` were set at the same time, therefore showPercent format will be ignored',
        );
      }

      formatPattern = currencyFormat
        .split(';')
        .map((item, index) => {
          if (index > 0) {
            return item;
          }

          return `<span dir="ltr">${item}</span>`;
        })
        .join(';');
    }

    const [generalPattern, negativePattern] = formatPattern.split(';');

    if (this.isZero) {
      return generalPattern;
    } else if (this.isPositive) {
      return this.showPlusSign ? `${plusSymbol}${generalPattern}` : generalPattern;
    }

    return this.amountFormatWithSign(negativePattern || generalPattern, minusSymbol);
  }

  private amountFormatWithSign(amountPattern: string, sign: string) {
    if (amountPattern.includes(sign)) {
      // use angular formatting for case '¤ #,##0.00;¤ -#,##0.00'
      return amountPattern;
    }

    if (this.signPosition === 'right' && !isRtl() && !/#.*¤/.test(amountPattern)) {
      // do not replace if currency unit is the last in the amount patten (2.00 USD- is wrong)
      return amountPattern.replace('¤', `¤${sign}`);
    }

    return `${sign}${amountPattern}`;
  }

  private get transformedAmount(): string {
    const positiveAmount = this._amountValue.abs();
    const configDecimalPlaces = this.configDecimalPlaces;
    const shouldAbbreviate =
      this.abbreviate && positiveAmount.isGreaterThanOrEqualTo(AmountComponent.abbreviationConfig.minAmount);

    /**
     * Example:
     *
     * {@link groupSymbol} = ','
     * {@link decimalSymbol} = '.'
     * {@link configDecimalPlaces} = '2'
     *
     * Here how regexp source looks line with config above: ([\d,]+[.]\d{2}).*
     * This regexp has two selections:
     * - selection for the group `([\d,]+[.]\d{2})` to match the decimal precision (no rounding)
     * - and full string selection `.*` (used to replace full string with the group)
     *
     * REGEXP GROUP MATCHES ONLY THE AMOUNTS THAT HAVE TO BE UPDATED (TRIMMED)
     *
     * There are the cases when it does not have an effect, and it means that amount is already in appropriate format
     */
    const regexp = new RegExp(`([\\d${this.groupSymbol}]+[${this.decimalSymbol}]\\d{${configDecimalPlaces}}).*`, 'g');
    const amount = shouldAbbreviate ? this.getAbbreviatedAmount(positiveAmount) : positiveAmount;
    const amountDecimalPlaces = amount.decimalPlaces();
    // In order to keep the precision keeping the original decimal places and replace with regexp
    const formattedAmount = amount
      // @ts-ignore
      .toFormat(amountDecimalPlaces, BigNumber.ROUND_CEIL, this.numberFormat)
      .replace(regexp, '$1');

    // @ts-ignore
    return this.getAmountWithZeroPaddings(formattedAmount, configDecimalPlaces, amountDecimalPlaces);
  }

  private getAmountWithZeroPaddings(
    formattedAmount: string,
    configDecimalPlaces: number,
    amountDecimalPlaces: number,
  ): string {
    if (!this.trailingZeroes || amountDecimalPlaces >= configDecimalPlaces) {
      return formattedAmount;
    }

    const amountOfZeros = configDecimalPlaces - amountDecimalPlaces;
    let stringToAppend = '0'.repeat(amountOfZeros);

    if (!formattedAmount.includes(this.decimalSymbol)) {
      stringToAppend = this.decimalSymbol + stringToAppend;
    }

    return formattedAmount + stringToAppend;
  }

  private setupConfiguration() {
    if (!AmountComponent.localeConfig) {
      AmountComponent.localeConfig = {
        currencyFormat: getLocaleNumberFormat(this.locale, NumberFormatStyle.Currency),
        decimalFormat: getLocaleNumberFormat(this.locale, NumberFormatStyle.Decimal),
        percentFormat: getLocaleNumberFormat(this.locale, NumberFormatStyle.Percent),
        decimalSymbol: getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal),
        groupSymbol: getLocaleNumberSymbol(this.locale, NumberSymbol.Group),
        currencyGroupSymbol: getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyGroup),
        currencyDecimalSymbol: getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal),
        minusSymbol: getLocaleNumberSymbol(this.locale, NumberSymbol.MinusSign),
        plusSymbol: getLocaleNumberSymbol(this.locale, NumberSymbol.PlusSign),
        percentSymbol: getLocaleNumberSymbol(this.locale, NumberSymbol.PercentSign),
      };
    }
  }

  private updateNumberFormat(): void {
    const { decimalSymbol, currencyDecimalSymbol, groupSymbol, currencyGroupSymbol } = AmountComponent.localeConfig;

    this.groupSymbol = this.currency ? currencyGroupSymbol : groupSymbol;
    this.decimalSymbol = this.currency ? currencyDecimalSymbol : decimalSymbol;
    this.numberFormat = {
      groupSeparator: this.groupSymbol,
      decimalSeparator: this.decimalSymbol,
      groupSize: 3,
    };
  }

  /**
   * Converts original amount to abbreviated
   * Max abbreviated suffix value is 'T' (trillion) see {@link AmountComponent.abbreviationConfig}
   */
  private getAbbreviatedAmount(positiveAmount: BigNumber): BigNumber {
    const suffixes = AmountComponent.abbreviationConfig.suffixes;
    const config = { suffix: suffixes[0], amount: positiveAmount.shiftedBy(-3) };

    for (let i = 1; i < suffixes.length; i++) {
      if (!config.amount.isGreaterThanOrEqualTo(1000)) {
        break;
      }

      config.suffix = suffixes[i];
      config.amount = config.amount.shiftedBy(-3);
    }

    this.abbreviationSuffix = config.suffix;

    return config.amount;
  }

  private getAmountTemplate(amount: string): string {
    const { percentSymbol, minusSymbol, plusSymbol } = AmountComponent.localeConfig;
    const signSymbol = this.isPositive ? plusSymbol : minusSymbol;
    const currencySymbol = this.mapCurrency
      ? getCurrencySymbol(this.currency, this.currencyFormat, this.locale)
      : this.currency;

    return this.amountFormat
      .replace(signSymbol, this.fillTemplate('sign', signSymbol))
      .replace(/[#0,.]+/g, this.wrapAmount(amount))
      .replace('¤', this.fillTemplate('currency', currencySymbol))
      .replace('%', this.fillTemplate('percent', percentSymbol));
  }

  private wrapAmount(amount: string): string {
    const [integer, decimals] = amount.split(this.decimalSymbol);

    return (
      this.fillTemplate('integer', integer) +
      (decimals ? this.fillTemplate('separator', this.decimalSymbol) + this.fillTemplate('decimals', decimals) : '')
    );
  }

  private fillTemplate(templateKey: keyof typeof AmountComponent.templates, value: string): string {
    return AmountComponent.templates[templateKey].replace('{{}}', value);
  }

  // ensure BigNumber can properly read numbers from other locales that have commas
  private toParsableNumber(amount: string | number): string | number {
    return typeof amount === 'string' ? amount.replace(',', '.') : amount;
  }
}
