import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';

@Directive({ selector: 'ng-template[bbCustomProductItemInvestmentAccountTitle]' })
export class CustomProductItemInvestmentAccountTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemInvestmentAccountDetailsFormatter]' })
export class CustomProductItemInvestmentAccountDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemInvestmentAccountComponent
 *
 * @description
 * A component that renders a styled-container or card for investment account.
 */
@Component({
  selector: 'bb-product-item-investment-account-ui',
  templateUrl: './product-item-investment-account.component.html',
})
export class ProductItemInvestmentAccountComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemInvestmentAccountTitleDirective, { static: true })
  customProductItemInvestmentAccountTitle: CustomProductItemInvestmentAccountTitleDirective | undefined;

  @ContentChild(CustomProductItemInvestmentAccountDetailsFormatterDirective, { static: true })
  customProductItemInvestmentAccountDetailsFormatter:
    | CustomProductItemInvestmentAccountDetailsFormatterDirective
    | undefined;

  readonly hostRef: ProductItemInvestmentAccountComponent = this;

  /**
   * The card currency.
   */
  @Input() currency: string | undefined;
  /**
   * The card amount value.
   */
  @Input() amount: number | undefined;
}
