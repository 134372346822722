import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';
import { filter, distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';

export interface SelectListItemBase {
  name: string;
}

export interface SearchPayload {
  term: string;
  items: Array<any>;
}

@Component({
  selector: 'bb-select-list-ui',
  templateUrl: './select-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectListComponent),
    },
  ],
})
// TODO: set a generic that extends to SelectListItemBase once Ivy check types step supports correctly types
export class SelectListComponent<T extends { name: string }>
  extends InputBaseComponent
  implements OnChanges, AfterViewInit
{
  @ViewChild('select') ngSelect: NgSelectComponent | undefined;

  /**
   * Flag to determine if the data in the list is loading
   */
  @Input() loading = false;

  /**
   * Placeholder text to display in the input
   * */
  @Input() placeholder = '';

  /**
   * Minimum number of characters required before searching for items
   * */
  @Input() minTermLength = 2;

  /**
   * Maximum number of selected items, 'none' means unlimited
   */
  @Input() maxSelectedItems = 'none';

  /**
   * Flag to determine if the list allows multiple selection.
   * */
  @Input() multiple = false;

  /**
   * A list of items to select from based on the input text.
   * */
  @Input() items: T[] = [];

  /**
   * Flag to determine if typeahead should be used to filter the items list.
   * If enabled the items list can not be updated asynchonously as the filtering
   * and the underlying data will get out of sync.
   */
  @Input() filterEnabled = true;

  /**
   * Output to capture value change event.
   */
  @Output() valueChange = this._valueChange;

  /**
   * Emitter when search is performed. Outputs search term
   */
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Emitter when clear is performed.
   */
  @Output() clear = new EventEmitter<void>();

  /**
   * Delay in ms between autocomplete updates.
   * */
  private readonly DEBOUNCE_TIME_MS = 500;

  /**
   * Observable of the typeahead input
   */
  readonly typeaheadInput$ = new Subject<string>();
  /**
   * Observable of the filtered items
   */
  readonly filteredItems$: Observable<T[]> = this.typeaheadInput$.pipe(
    filter((term) => term !== null && term.length >= this.minTermLength),
    distinctUntilChanged(),
    debounceTime(this.DEBOUNCE_TIME_MS),
    map((term) => this.items.filter(({ name }: any) => name.toLowerCase().includes(term.toLowerCase()))),
  );

  writeValue(inputValue: Object | string | null): void {
    if (inputValue !== null) {
      super.writeValue(inputValue);
    } else {
      this.value = inputValue;
      this.cd.markForCheck();
    }
  }

  onSearch(event: string | SearchPayload) {
    const searchTerm = typeof event === 'string' ? event : event.term;

    if (searchTerm.length >= this.minTermLength) {
      this.search.emit(searchTerm);
    }
    if (searchTerm?.length === 0 && this.ngSelect?.isOpen === true) {
      this.ngSelect.isOpen = false;
    }
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.ngSelect?.isOpen === false && this.ngSelect?.searchTerm) {
      this.ngSelect.isOpen = true;
    }
  }

  constructor(cd: ChangeDetectorRef, private readonly renderer: Renderer2) {
    super(cd);
  }

  ngAfterViewInit(): void {
    // HACK: ng-select does not have "aria-controls" on it's combobox
    // We move the "aria-controls" attached on combobox > input to combobox
    if (!this.ngSelect?.dropdownId) {
      return;
    }

    const comboboxElement = this.ngSelect?.element.querySelector('[role="combobox"]');
    const inputElement = this.ngSelect?.element.querySelector(`#${this.id}`);

    this.renderer.setAttribute(comboboxElement, 'aria-controls', this.ngSelect.dropdownId);
    this.renderer.removeAttribute(inputElement, 'aria-controls');
  }

  onOpen() {
    // HACK: ng-select added a duplicate role=listbox as of v11 in https://github.com/ng-select/ng-select/pull/2199
    // We move role=list and aria-label on ng-dropdown-panel to the child div

    // Wait for dropdown from ng-select to show
    setTimeout(() => {
      const dropdownPanelElement = this.ngSelect?.element.querySelector('ng-dropdown-panel');
      const listboxElement = dropdownPanelElement?.querySelector('[role="listbox"]');

      this.renderer.setAttribute(listboxElement, 'aria-label', dropdownPanelElement?.getAttribute('aria-label') ?? '');
      this.renderer.removeAttribute(dropdownPanelElement, 'role');
      this.renderer.removeAttribute(dropdownPanelElement, 'aria-label');
    });
  }
}
