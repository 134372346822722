import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { defaultSize, InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { isRtl } from '@backbase/ui-ang/util';

export interface TypeaheadOptions<T> {
  ngbTypeahead: (text: Observable<string>) => Observable<Array<T>>;
  editable?: boolean;
  focusFirst?: boolean;
  showHint?: boolean;
  placement?: string;
  inputFormatter?: (item: T) => string;
  resultFormatter?: (item: T) => string;
  resultTemplate?: TemplateRef<any>;
  selectItem?: (item: NgbTypeaheadSelectItemEvent) => void;
  groupCssClasses?: string;
}

const SIZE_MEDIUM = defaultSize;
const SIZE_SMALL = SIZE_MEDIUM / 2;

const KEYWORD_SMALL = 'sm';
const KEYWORD_MEDIUM = 'md';

/**
 * @name SearchBoxComponent
 *
 * @description
 * Component that provides you a search input field.
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - Set aria-describedby  with an element id that contains a detailed decription about the Search box.
 *  - aria-label or  aria-labelledby can be used to display the label for search box.
 *
 * If  showSearch = true , searchLabel is discernible text for search button.
 * If  showClear = true , clearLabel is discernible text for clear button.
 *
 */
@Component({
  selector: 'bb-search-box-ui',
  templateUrl: './search-box.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchBoxComponent),
    },
  ],
})
export class SearchBoxComponent<T> extends InputBaseComponent implements AfterViewInit, DoCheck {
  /**
   * The placeholder text to display in the input.
   */
  @Input() placeholder = '';
  /**
   * The maximum length of the search text. Defaults to 140.
   */
  @Input() maxLength = '140';
  /**
   * The square border for search box. Defaults to false.
   */
  @Input() squareBorder = false;
  /**
   * The list of options to use when typeahead is available.
   * See https://ng-bootstrap.github.io/#/components/typeahead/api#NgbTypeahead for the list of options.
   */
  @Input() typeaheadOptions: TypeaheadOptions<T> | undefined;
  /**
   * Whether to show the search button icon before the text. Defaults to false.
   */
  @Input() showSearch = false;
  /**
   * Whether to show the clear button icon after the text. Defaults to false.
   */
  @Input() showClear = false;
  /**
   * The aria label used when the search button is in focus.
   */
  @Input() searchLabel = 'Search';
  /**
   * The aria label used when the clear button is in focus.
   */
  @Input() clearLabel = 'Clear';
  /**
   * The autocomplete attribute value. Defaults to off.
   */
  @Input() autocomplete = 'off';
  /**
   * The role of the container for the search input. For non-landmarked search, please assign this a value. Defaults to 'search'.
   */
  @Input() containerRole = 'search';
  /**
   * The label of the container for the search input. When there are multiple search inputs on a page, a label should be assigned. Defaults to ''.
   */
  @Input() containerAriaLabel = '';
  /**
   * Emits an event when a search is submitted.
   */
  @Output() submit: EventEmitter<string | boolean | Object | null> = new EventEmitter<
    string | boolean | Object | null
  >();
  /**
   * Emits an event when the clear button is clicked.
   */
  @Output() clear: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Emits an event when the value in the search box is changed.
   */
  @Output() valueChange: EventEmitter<any> = this._valueChange;

  @ViewChild('searchBox', { static: true }) searchBox: ElementRef | undefined;
  @ViewChild('inputField') inputField: ElementRef | undefined;
  @ViewChild(NgbTypeahead) ngbTypeahead: NgbTypeahead | undefined;

  readonly labelId = `bb_searchbox_label_${this.id}`;
  readonly isRtl = isRtl;

  hasAppendContent = true;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    private readonly domAttrService: DomAttributesService,
    private readonly elem: ElementRef,
  ) {
    super(cd);
  }

  onSubmit() {
    this.submit.emit(this.value);
  }

  ngAfterViewInit() {
    this.domAttrService.moveAriaAttributes(this.elem.nativeElement, this.inputField?.nativeElement, this.renderer);

    if (this.inputField !== undefined) {
      this.inputField.nativeElement.removeAttribute('aria-multiline');
    }
    this.cd.detectChanges();
  }

  ngDoCheck() {
    if (this.searchBox) {
      const listBoxElem = this.searchBox.nativeElement?.querySelector('[role="listbox"]:not([aria-labelledby])');

      if (listBoxElem && this.label) {
        this.renderer.setAttribute(listBoxElem, 'aria-labelledby', this.labelId);
      }

      this.cd.detectChanges();
    }
  }

  onClear() {
    this.value = '';
    if (this.inputField !== undefined) {
      this.inputField.nativeElement.focus();
    }
    this.clear.emit();
  }

  /**
   * Sets the focus on the input field.
   * If the input field reference is defined, it calls the `focus()` method on the native element.
   */
  focusOnInputField() {
    if (this.inputField !== undefined) {
      this.inputField.nativeElement.focus();
    }
  }

  onFocus() {
    if (this.searchBox === undefined) {
      return;
    }
    this.searchBox.nativeElement.classList.add('bb-focus');
    super.onFocus();
  }

  onBlur() {
    if (this.searchBox === undefined) {
      return;
    }
    this.searchBox.nativeElement.classList.remove('bb-focus');
    super.onBlur();
  }

  isClearVisible() {
    return this.showClear && this.value;
  }

  focusEditableElement() {
    if (this.searchBox) {
      this.searchBox.nativeElement.focus();
    }
  }

  getKeywordBySize(size: number | string): string {
    if (Number(size) <= SIZE_SMALL) {
      return KEYWORD_SMALL;
    } else {
      return KEYWORD_MEDIUM;
    }
  }

  getSizeByKeyword(keyword: any): number | undefined {
    const sizeKeywords: { [index: string]: number } = {
      [KEYWORD_SMALL]: SIZE_SMALL,
      [KEYWORD_MEDIUM]: SIZE_MEDIUM,
    };

    return typeof keyword === 'string' && sizeKeywords.hasOwnProperty(keyword) ? sizeKeywords[keyword] : undefined;
  }

  onSelect(event: any) {
    return this.typeaheadOptions?.selectItem?.(event);
  }
}
