import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { DomSanitizer } from '@angular/platform-browser';
import { registerCustomIconsForQuill } from './helpers/icons-register';
import Quill, { RangeStatic, StringMap } from 'quill';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';
import { FORMAT_GROUPS } from './helpers/formats';
import { RichTextEditorActions, QuillEditorActionsAdapter, TextEditorFormatGroup } from './helpers/models';
import { isRtl } from '@backbase/ui-ang/util';

const Delta = Quill.import('delta');

registerCustomIconsForQuill();

/**
 * @name RichTextEditorComponent
 *
 * @description
 * Component that provides you input text with editing.
 */
@Component({
  selector: 'bb-rich-text-editor-ui',
  templateUrl: './rich-text-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
  ],
})
export class RichTextEditorComponent
  extends InputBaseComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy
{
  /**
   * The maxLength for the rich-text-editor component.
   */
  @Input() maxLength = Infinity;
  /**
   * The minLength for the rich-text-editor component.
   */
  @Input() minLength = 0;
  /**
   * The placeholder for the rich-text-editor component. Defaults to an empty string.
   */
  @Input() placeholder = '';
  /**
   * Show/hide message length. Defaults to true.
   */
  @Input() counter = true;
  /**
   * Placement of the action tooltip relative to the action button: auto | top | bottom | left | right. Defaults to 'auto'.
   */
  @Input() actionsTooltipPlacement = 'auto';

  /**
   * Actions of the editor toolbar.
   */
  @Input() actions: RichTextEditorActions[] = [
    RichTextEditorActions.Bold,
    RichTextEditorActions.Italic,
    RichTextEditorActions.Underline,
    RichTextEditorActions.Strike,
    RichTextEditorActions.OrderedList,
    RichTextEditorActions.UnorderedList,
    RichTextEditorActions.Code,
    RichTextEditorActions.Link,
    RichTextEditorActions.Indent,
  ];
  @HostBinding('class.is-focused') focused = false;
  /**
   * The template of the text-editor component;
   */
  @ViewChild(QuillEditorComponent) readonly editor!: QuillEditorComponent;
  modalOpened = false;
  selectionUrl = '';
  blurred = false;
  public actionList: Record<string, boolean> = {};
  public readonly labelId: string;
  public readonly FORMAT_GROUPS: TextEditorFormatGroup = FORMAT_GROUPS;
  public readonly FORMAT_GROUPS_KEYS: any = Object.keys(FORMAT_GROUPS);
  public readonly EditorActions: Record<string, string> = RichTextEditorActions;
  public readonly QuillEditorActionsAdapter: Record<string, string> = QuillEditorActionsAdapter;
  private _messageText?: string;
  private _messageHTML?: string;
  private formatButtonsObserver!: MutationObserver;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private domAttributesService: DomAttributesService,
    private readonly sanitizer: DomSanitizer,
  ) {
    super(cd);
    this.labelId = this.domAttributesService.generateId();
  }

  get messageHTML(): string {
    return this._messageHTML || '';
  }

  set messageHTML(value: string | null) {
    this._messageHTML = this.parseValue(value || '');
  }

  get messageText(): string {
    return this._messageText || '';
  }

  set messageText(value: string | null) {
    this._messageText = value || '';
  }

  get quill(): Quill {
    return this.editor?.quillEditor;
  }

  get selection(): RangeStatic {
    return this.quill.getSelection(true);
  }

  get format(): StringMap {
    return this.quill?.getFormat(this.selection?.index, this.selection?.length) || {};
  }

  ngOnInit(): void {
    this.setActionTypes();
  }

  setActionTypes() {
    this.actionList = this.actions.reduce((acc, action) => ({ ...acc, [action]: true }), {});
  }

  changedEditor(event: ContentChange) {
    if (event.text) {
      this.messageText = this.parseTextFromQuill(event.text);
      this.messageHTML = event.html;
      this.callOnChange();
    }
  }

  writeValue(inputValue: string) {
    this.updateQuillContent(inputValue);
  }

  updateQuillContent(inputValue: string = this.messageText) {
    setTimeout(() => {
      const delta = this.quill?.clipboard?.convert(inputValue || '');
      this.editor?.quillEditor?.setContents(delta);
    }, 0);
  }

  callOnChange() {
    super.onValueChange(this.messageHTML);
    this.cd.markForCheck();
  }

  callOnFocus() {
    setTimeout(() => {
      super.onFocus();
      this.focused = true;
      this.blurred = false;
      this.cd.markForCheck();
    });
  }

  callOnBlur() {
    setTimeout(() => {
      super.onBlur();
      this.focused = false;
      this.blurred = true;
      this.cd.markForCheck();
    });
  }

  validate(control?: AbstractControl): ValidationErrors | null {
    const value = this.getMessageText() || null;
    const validators = [Validators.maxLength(this.maxLength), Validators.minLength(this.minLength)];

    if (this.required) {
      validators.push(this.customRequiredValidation.bind(this));
    }

    const compose = Validators.compose(validators);

    return compose && compose({ value } as AbstractControl);
  }

  labelClick() {
    this.focusEditor();
  }

  getMessageText(): string {
    return this.messageText.replace(/\n/g, '');
  }

  showCounter(): boolean {
    return this.counter && !!this.maxLength && Number.isFinite(this.maxLength);
  }

  setSelectionUrl() {
    this.selectionUrl = this.format?.link || '';
  }

  openLinkModal() {
    this.setSelectionUrl();
    this.modalOpened = true;
  }

  onResult() {
    this.insertLink();
    this.closeModal();
  }

  closeModal() {
    this.modalOpened = false;
    this.focusEditor();
  }

  configureToolbar() {
    const toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('link', () => this.openLinkModal());
    this.checkRtlDirection();
    this.configureKeyboard();
    this.handleRootEvents();
    this.observeButtonsState();
  }

  ngOnDestroy(): void {
    this.formatButtonsObserver?.disconnect();
  }

  private checkRtlDirection() {
    if (isRtl()) {
      setTimeout(() => {
        this.quill.disable();
        this.quill.format('direction', 'rtl');
        this.quill.format('align', 'right');
        this.quill.enable();
      }, 0);
    }
  }

  private configureKeyboard() {
    delete this.quill.getModule('keyboard').bindings['9'];
    const keyb = this.quill.keyboard;
    keyb.addBinding({ key: 'tab' }, () => true);
  }

  private handleRootEvents() {
    this.quill.root.onfocus = () => {
      this.callOnFocus();
    };
    this.quill.root.onblur = (event) => {
      if (!event.relatedTarget || (event.relatedTarget as any).localName !== 'button') {
        this.callOnBlur();
      }
    };
  }

  private observeButtonsState() {
    this.formatButtonsObserver = new MutationObserver((mutations) => {
      mutations.forEach((mu) => {
        if (mu.attributeName === 'class') {
          const button = mu.target as HTMLElement;
          this.setFormatButtonAriaPressedState(button);
        }
      });
    });

    setTimeout(() => {
      const formatButtons = document.querySelectorAll('.ql-format-group button');
      formatButtons.forEach((el) => {
        this.setFormatButtonAriaPressedState(el as HTMLElement);
        this.formatButtonsObserver.observe(el, { attributes: true, attributeFilter: ['class'] });
      });
    }, 0);
  }

  private focusEditor() {
    this.quill?.focus();
  }

  private insertLink() {
    this.selectionUrl = this.selectionUrl.replace(/\s/g, '');

    if (this.selectionUrl.length && !/^(http:\/\/|https:\/\/)/.test(this.selectionUrl)) {
      this.selectionUrl = 'https://' + this.selectionUrl;
    }

    const selection = this.selection;
    const oldText = this.quill.getText(selection?.index, selection?.length);
    const link = this.selectionUrl;
    const format = this.format;
    delete format.link;
    const text = oldText || link;
    const delta = new Delta()
      .retain(selection?.index)
      .delete(selection?.length)
      .insert(text, { link, ...format });
    this.quill.updateContents(delta);
    const index = Number(text?.length + selection?.index);
    this.quill.setSelection({ index, length: 0 });
  }

  private parseTextFromQuill(value = '') {
    const pattern = /\n/;
    const isNewLine = pattern.test(value.slice(-1));

    return isNewLine ? value.substring(0, value.length - 1) : value;
  }

  private parseValue(value: string): string {
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(value);

    return this.replaceSpaces(isHtml ? this.sanitizer.sanitize(SecurityContext.HTML, value) || '' : value);
  }

  private readonly replaceSpaces = (value: string): string => value.replace(/&nbsp;|&#160;/g, ' ');

  private customRequiredValidation(): ValidationErrors | null {
    const value = this.getMessageText();

    if (!value || (typeof value === 'string' && !value.trim())) {
      return {
        required: true,
      };
    }

    return null;
  }

  private readonly setFormatButtonAriaPressedState = (button: HTMLElement) => {
    if (button.classList.contains('ql-active')) {
      button.setAttribute('aria-pressed', 'true');
    } else {
      button.setAttribute('aria-pressed', 'false');
    }
  };
}
