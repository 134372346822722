import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { isRtl } from '@backbase/ui-ang/util';

/**
 * @name PaginatorComponent
 *
 * @description
 * A component that displays page numbers and allows to customize them in several ways.
 */
@Component({
  selector: 'bb-pagination-ui',
  templateUrl: './pagination.component.html',
  providers: [NgbPaginationConfig],
})
export class PaginatorComponent {
  paginationIcons = {
    first: isRtl() ? 'last-page' : 'first-page',
    last: isRtl() ? 'first-page' : 'last-page',
    previous: isRtl() ? 'chevron-right' : 'chevron-left',
    next: isRtl() ? 'chevron-left' : 'chevron-right',
  };

  /**
   * The current page.
   *
   * `bb-paginator-ui` is 0-indexed, but `ngb-pagination` is 1-indexed. Corrects input by adding 1 internally.
   */
  @Input() page = 1;
  /**
   * If `true`, the "First" and "Last" page links are shown. Defaults to false.
   */
  @Input() boundaryLinks = false;
  /**
   * If `true`, the "Next" and "Previous" page links are shown. Defaults to false.
   */
  @Input() directionLinks = false;
  /**
   * The number of items in your paginated collection.
   *
   * Note, that this is not the number of pages. Page numbers are calculated dynamically based on
   * `totalItems` and `itemsPerPage`.
   */
  @Input() totalItems = 0;
  /**
   * The number of items per page.
   */
  @Input() itemsPerPage = 10;
  /**
   * The maximum number of pages to display.
   */
  @Input() maxNavPages = 5;
  /**
   * An event fired when the page is changed. Will fire only if collection size is set and all values are valid.
   *
   * Event payload is the number of the newly selected page.
   *
   * `bb-paginator-ui` is 0-indexed, but `ngb-pagination` is 1-indexed. Corrects output by subtracting 1 internally.
   */
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  onPageChange(page: number) {
    this.pageChange.emit(page - 1);
  }
}
