<div
  (openChange)="onOpenChange($event)"
  [autoClose]="autoClose"
  [container]="container"
  [placement]="position"
  class="bb-dropdown-btn-group-wrapper"
  ngbDropdown
>
  <button
    bbButton
    #button
    [attr.aria-label]="dropDownButtonLabel"
    [attr.data-role]="'dropdown-menu-toggle-button'"
    [attr.aria-owns]="disabled ? null : dropDownMenuId ?? dropdownId"
    [block]="fullWidth"
    [circle]="btnCircle"
    [color]="btnColor"
    [buttonSize]="buttonSize"
    [disabled]="disabled"
    ngbDropdownToggle
  >
    <span
      class="dropdown-menu-toggle-button__content"
      [ngClass]="{ 'dropdown-menu-toggle-button__content--full-width': fullWidth }"
    >
      @if (customLabel) {
        <ng-container *ngTemplateOutlet="customLabel"></ng-container>
      } @else {
        @if (label) {
          <span
            class="dropdown-menu__label"
            [ngClass]="{ 'dropdown-menu__label--full-width': fullWidth }"
            [attr.data-role]="'dropdown-menu-label'"
            >{{ label }}</span
          >
        }
      }
      @if (icon) {
        <bb-icon-ui [color]="iconColor" [name]="icon" [size]="iconSize" [cropped]="!btnCircle"></bb-icon-ui>
      }
    </span>
  </button>
  @if (!disabled) {
    <div
      #dropdownMenuContainer
      class="dropdown-menu"
      ngbDropdownMenu
      [attr.role]="dropDownMenuRole"
      [attr.data-role]="'dropdown-menu'"
      [attr.id]="dropDownMenuId ?? dropdownId"
    >
      @if (customDropdownMenuItem) {
        <ng-container *ngTemplateOutlet="customDropdownMenuItem"></ng-container>
      } @else {
        @for (option of options; track $index) {
          <a
            ngbDropdownItem
            role="menuitem"
            href="#"
            [attr.id]="dropdownId + $index"
            class="dropdown-item"
            #dropdownListElement
            (click)="onClick($event, option)"
            (keydown.enter)="onClick($event, option)"
            [attr.data-role]="'dropdown-menu-item'"
          >
            {{ getOptionLabel(option) }}
          </a>
        }
      }
    </div>
  }
</div>
