/**
 * module bb-iban-lib
 * description IBAN structure validation library
 */

// References:
// https://www.swift.com/standards/data-standards/iban
// https://en.wikipedia.org/wiki/International_Bank_Account_Number

import countrySpecification from './countrySpec';
import { compact, convertIban, bbanRegexp, explodeString } from './helpers';
import bigIntMod from './bigIntegerMod';
import { AbstractControl } from '@angular/forms';

const isInvalidCheckDigits = (digits: string) => ['00', '01', '99'].includes(digits);

/**
 * Validates IBAN structure defined in ISO 13616-1 and ISO/IEC 7064 (MOD97-10).
 *
 * name isValidIBAN
 * type {function}
 * param {string} iban International bank account number.
 * returns {boolean}
 */
export function isValidIBAN(iban: string): boolean {
  if (typeof iban !== 'string') {
    return false;
  }

  let spec;
  const compactIBAN = compact(iban.toUpperCase());

  if (isInvalidCheckDigits(compactIBAN.substring(2, 4))) {
    return false;
  }

  try {
    spec = countrySpecification(compactIBAN);
  } catch (error) {
    return false;
  }

  const specBban = bbanRegexp(spec.bban);
  if (!specBban) return false;

  return (
    spec.len === compactIBAN.length &&
    specBban.test(compactIBAN.slice(4)) &&
    bigIntMod(convertIban(compactIBAN), 97) === 1
  );
}

/**
 * Validates IBAN structure defined in ISO 13616-1 and ISO/IEC 7064 (MOD97-10).
 *
 * @name isValidIBANFormControl
 * @param control conntrol.value = {string} iban International bank account number.
 * @example: NL91ABNA0417164300
 */
export function isValidIBANFormControl(control: AbstractControl): null | { [key: string]: any } {
  const iban = control.value;
  if (typeof iban !== 'string') {
    return { invalidIban: { value: control.value } };
  }

  let spec;
  const compactIBAN = compact(iban.toUpperCase());

  // validate check digits
  if (isInvalidCheckDigits(compactIBAN.substring(2, 4))) {
    return { invalidIban: { value: control.value } };
  }

  // check country support IBAN
  try {
    spec = countrySpecification(compactIBAN);
  } catch (error) {
    return { invalidIban: { value: control.value } };
  }

  const specBban = bbanRegexp(spec.bban);
  if (!specBban) return { invalidIban: { value: control.value } };

  // check IBAN number length
  // check account number checksum in BBAN
  const res: boolean =
    spec.len === compactIBAN.length &&
    specBban.test(compactIBAN.slice(4)) &&
    bigIntMod(convertIban(compactIBAN), 97) === 1;

  return res ? null : { invalidIban: { value: control.value } };
}

/**
 * Validates BBAN structure defined in ISO 13616-1.
 *
 * name isValidBBAN
 * type {function}
 * param {string} iban International bank account number.
 * returns {boolean}
 */
export function isValidBBAN(iban: string): boolean {
  if (typeof iban !== 'string') {
    return false;
  }

  let spec;
  const compactIBAN = compact(iban);

  try {
    spec = countrySpecification(compactIBAN);
  } catch (error) {
    return false;
  }

  // Remove country code and check digits
  const bban = compactIBAN.slice(4);

  const specBban = bbanRegexp(spec.bban);
  if (!specBban) return false;

  return bban.length === spec.len - 4 && specBban.test(bban);
}

/**
 * name formatByGroups
 * type {function}
 *
 * description
 * Splits the string up with spaces by groups of 4.
 * "IE47FNLL45049097007367" => "IE47 FNLL 4504 9097 0073 67"
 *
 * param str {string}
 * param symbol {string}
 * param groupLen {number}
 * return {string}
 */
export function formatByGroups(str: string, symbol: string, groupLen: number): string {
  return explodeString(str, symbol, groupLen);
}
