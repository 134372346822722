import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-infinite-scroll-ui-showcase',
  templateUrl: './infinite-scroll-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InfiniteScrollShowcaseComponent {
  page = 0;
  complete = false;
  loading = false;
  sum = 10;
  fixedText: string =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
  items: string[] = Array(10).fill(this.fixedText);

  constructor() {}

  loadMore() {
    if (!this.loading) {
      this.loading = true;
      this.page++;

      window.setTimeout(() => {
        this.loading = false;
        this.addItems();
        this.complete = this.page >= 5;
      }, 1000);
    }
  }

  addItems() {
    for (let i = 0; i < this.sum; ++i) {
      this.items.push(this.fixedText);
    }
  }
}
