<fieldset
  [attr.aria-describedby]="ariaDescribedby"
  class="bb-input-radio-group"
  [attr.aria-label]="ariaLabel"
  [attr.aria-labelledby]="ariaLabelledby"
  (focusout)="focusOut()"
>
  @if (labelTemplate && !label) {
    <ng-container
      *ngTemplateOutlet="labelTemplate; context: { labelTemplateContext: labelTemplateContext }"
    ></ng-container>
  }
  @if (label) {
    <legend class="bb-input-radio-group__label">{{ label }}</legend>
  }
  <div class="bb-input-radio-group__list" [ngClass]="{ 'bb-input-radio-group__list--horizontal': horizontal }">
    @for (radio of radios; track trackByFn($index, radio)) {
      <label
        class="bb-input-radio-group__radio form-label bb-input-radio-group__radio--vertical-align-{{ verticalAlign }}"
        [ngClass]="{
          'selected active': inputRadio.value === radio.value,
          focus: focusedValue === radio.value,
          disabled: disabled || radio.disabled
        }"
        (click)="setValue(radio)"
        (keydown.Space)="setValue(radio)"
        [attr.data-role]="'radio-group-option'"
      >
        <input
          class="bb-input-radio-group__input"
          type="radio"
          [attr.name]="name"
          (focus)="focusValue(radio)"
          [bbFocus]="autofocus"
          [value]="radio.value"
          [readOnly]="readonly"
          [required]="radio.disabled ? false : required"
          [formControl]="inputRadio"
          [attr.disabled]="disabled || radio.disabled ? true : null"
          [attr.aria-label]="radio.ariaLabel"
          [attr.aria-labelledby]="radio.ariaLabelledby"
          [attr.aria-describedby]="radio.ariaDescribedby"
          [attr.aria-expanded]="radio.ariaExpanded"
          [attr.role]="radio.role"
          [attr.aria-invalid]="radio.ariaInvalid"
          [attr.aria-owns]="radio.ariaOwns"
          [attr.data-id]="radio.id ? radio.id + '_input-radio-group_radio' : undefined"
        />
        @if (radio.label) {
          <span class="bb-input-radio-group__radio-label">
            {{ radio.label }}
          </span>
        }
        @if (!radio.label) {
          <ng-container
            *ngTemplateOutlet="radio.labelTemplate; context: { labelTemplateContext: radio.labelTemplateContext }"
          ></ng-container>
        }
      </label>
    }
  </div>
</fieldset>
