import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SortEvent, TableSortableDirective } from './table-sortable.directive';

/**
 * @name TableDirective
 *
 * @description
 * Directive that displays a table.
 *
 * @a11y
 * The table directive doesn't provide any specific properties for accessibility.
 * The `aria-sort` tag can be set on the sortable header cells with ascending and descending values.
 *
 */
@Directive({
  selector: 'table[bbTable]',
})
export class TableDirective {
  /**
   * The data source for the table.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('bbTable') dataSource?: Array<Object>;
  /**
   * Emits an event when a row is selected.
   */
  @Output() selectRow = new EventEmitter();
  /**
   * Emits an event when a row is clicked.
   */
  @Output() rowClick = new EventEmitter();
  /**
   * Emits an event when the column is sorted.
   */
  @Output() sort = new EventEmitter();

  selectedRows: Set<Object> = new Set();
  headers: Set<TableSortableDirective> = new Set();

  registerHeader(header: TableSortableDirective) {
    this.headers.add(header);
  }

  unRegisterHeader(header: TableSortableDirective) {
    this.headers.delete(header);
  }

  onRowClick(rowItem: any) {
    this.rowClick.emit(rowItem);
  }

  isSelected(row: any): boolean {
    return this.selectedRows.has(row);
  }

  toggleItem(row: any, isChecked: boolean) {
    if (isChecked) {
      this.selectedRows.add(row);
    } else {
      this.selectedRows.delete(row);
    }
    this.selectRow.emit(this.selectedRows);
  }

  toggleAllItems() {
    if (this.isSelectedAll()) {
      this.selectedRows = new Set();
    } else {
      this.selectedRows = new Set(this.dataSource);
    }
    this.selectRow.emit(this.selectedRows);
  }

  isSelectedAll(): boolean {
    if (this.dataSource) {
      const matches = this.dataSource.filter((item) => this.selectedRows.has(item));

      return matches.length === this.dataSource.length;
    }

    return false;
  }

  isSelectedPart(): boolean {
    if (this.dataSource) {
      return this.selectedRows.size > 0 && this.selectedRows.size < this.dataSource.length;
    }

    return false;
  }

  onSort(sortEvent: SortEvent) {
    this.headers.forEach((header) => {
      if (header.bbSortable !== sortEvent.column) {
        header.direction = '';
        header.markAllMenuOptionAsInactive();
      }
    });
    this.sort.emit(sortEvent);
  }
}
