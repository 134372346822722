import { Component } from '@angular/core';

import { BackbaseDocs } from '@backbase/ui-ang/util';
import { NotificationService } from '@backbase/ui-ang/notification';

@Component({
  selector: 'bb-button-ui-showcase',
  templateUrl: 'button-showcase.component.html',
  styleUrls: ['button-showcase.component.scss'],
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ButtonShowcaseComponent {
  readonly buttons: any = [
    {
      color: 'primary',
      text: 'Primary',
      icon: {
        name: 'add',
      },
      canBeUsedOnColor: true,
    },
    {
      color: 'secondary',
      text: 'Secondary',
      icon: {
        name: 'add',
        color: 'primary',
      },
      canBeUsedOnColor: true,
    },
    {
      color: 'link',
      text: 'Link',
      icon: {
        name: 'add',
        color: 'primary',
      },
      canBeUsedOnColor: true,
    },
    {
      color: 'link-text',
      text: 'Link Text',
      noDisabledState: true,
      icon: {
        name: 'arrow-back',
        color: 'primary',
      },
      canBeUsedOnColor: true,
    },
    {
      color: 'neutral',
      text: 'Neutral',
      icon: {
        name: 'add',
      },
    },
    {
      color: 'success',
      text: 'Success',
    },
    {
      color: 'danger',
      text: 'Danger',
    },
    {
      color: 'unstyled',
      text: 'Unstyled',
    },
  ];
  readonly barButtonVariants: any = ['primary', 'secondary', 'link'];
  barButtons: any[];
  buttonsWithIcon: any[];
  buttonsOnColor: any[];
  disabledButtons: any[];
  disabledButtonsWithIcon: any[];
  groups = {
    'sm-1': ['primary', 'secondary'],
    'sm-2': ['primary', 'secondary'],
    'sm-3': ['primary', 'secondary', 'secondary'],
    'md-1': ['primary', 'secondary'],
    'md-2': ['primary', 'secondary'],
    'md-3': ['primary', 'secondary', 'secondary'],
  };

  constructor(private readonly notificationService: NotificationService) {
    this.barButtons = this.buttons.filter((button: any) => this.barButtonVariants.indexOf(button.color) !== -1);
    this.buttonsWithIcon = this.buttons.filter((button: any) => !!button.icon);
    this.disabledButtons = this.buttons.filter((button: any) => !button.noDisabledState);
    this.disabledButtonsWithIcon = this.disabledButtons.filter((button: any) => !!button.icon);
    this.buttonsOnColor = this.buttons.filter((button: any) => button.canBeUsedOnColor);
  }

  showNotification(color: string) {
    const message = `Button ${color} is clicked`;
    this.notificationService.showNotification({
      header: 'Info notification',
      message: message,
      dismissible: true,
      ttl: 5000,
    });
    this.buttonsWithIcon = this.buttons.filter((button: any) => !!button.icon);
  }

  setActive(index: number, group: 'sm-1' | 'sm-2' | 'sm-3' | 'md-1' | 'md-2' | 'md-3') {
    this.groups[group] = this.groups[group].map(() => 'secondary');
    this.groups[group][index] = 'primary';
  }
}
