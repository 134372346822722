/**
 * Calculates remainder for numbers bigger than 2^53 - 1,
 * not supported in JavaScript.
 *
 * type {function}
 * inner
 * param  {string} dividend The dividend
 * param  {number} divisor  The divisor
 * return {number}          The remainder
 */
export default (dividend: string, divisor: number): number => {
  if (dividend.length < 10) {
    return parseInt(dividend, 10) % divisor;
  }

  let D = dividend.slice(9);
  let N = dividend.slice(0, 9);
  let remainder = parseInt(N, 10) % divisor;

  while (D.length > 7) {
    N = String(remainder) + D.slice(0, 7);
    remainder = parseInt(N, 10) % divisor;
    D = D.slice(7);
  }

  if (D.length) {
    N = String(remainder) + D;
  }

  return parseInt(N, 10) % divisor;
};
