<div class="row bb-block bb-block--md">
  <div class="col-sm-6 bb-block bb-block--md">
    <h4>Small button</h4>
    <div class="bb-block bb-block--md">
      <bb-dropdown-menu-ui container="body" icon="caret-down" btnColor="primary" buttonSize="sm">
        <ng-template bbDropdownLabel>
          <span>Dropdown with buttons</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          <button role="menuitem" class="dropdown-item">Dropdown item 1</button>
          <button role="menuitem" class="dropdown-item">Dropdown item 2</button>
          <button role="menuitem" class="dropdown-item">Dropdown item 3</button>
        </ng-template>
      </bb-dropdown-menu-ui>
    </div>
    <div class="bb-block bb-block--md">
      <bb-dropdown-menu-ui container="body" icon="caret-down" btnColor="secondary" buttonSize="sm" class="btn-group-sm">
        <ng-template bbDropdownLabel>
          <span>Dropdown with buttons</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          <button role="menuitem" class="dropdown-item">Dropdown item 1</button>
          <button role="menuitem" class="dropdown-item">Dropdown item 2</button>
          <button role="menuitem" class="dropdown-item">Dropdown item 3</button>
        </ng-template>
      </bb-dropdown-menu-ui>
    </div>
  </div>
  <div class="col-sm-6">
    <h4>Medium button</h4>

    <div class="bb-block bb-block--md">
      <bb-dropdown-menu-ui
        container="body"
        icon="caret-down"
        class="btn-md"
        btnColor="primary"
        iconSize="lg"
        [dropDownMenuRole]="'presentation'"
      >
        <ng-template bbDropdownLabel>
          <span>Dropdown with links</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          <ul>
            @for (option of dropdownTemplateMenuOptions; track $index) {
              <li>
                <a rel="noopener noreferrer" class="dropdown-item" target="_blank" [href]="option.url">
                  {{ option.name }}
                </a>
              </li>
            }
          </ul>
        </ng-template>
      </bb-dropdown-menu-ui>
    </div>
    <div class="bb-block bb-block--md">
      <bb-dropdown-menu-ui
        container="body"
        icon="caret-down"
        btnColor="secondary"
        iconSize="lg"
        [dropDownMenuRole]="'presentation'"
      >
        <ng-template bbDropdownLabel>
          <span>Dropdown with links</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          <ul>
            @for (option of dropdownTemplateMenuOptions; track $index) {
              <li>
                <a rel="noopener noreferrer" class="dropdown-item" target="_blank" [href]="option.url">
                  {{ option.name }}
                </a>
              </li>
            }
          </ul>
        </ng-template>
      </bb-dropdown-menu-ui>
    </div>
  </div>
</div>

<div class="row bb-block bb-block--md">
  <div class="col-sm-6 bb-block bb-block--md">
    <div class="bb-block bb-block--md">
      <bb-dropdown-menu-ui
        icon="caret-down"
        btnColor="primary"
        buttonSize="sm"
        [fullWidth]="true"
        [dropDownMenuRole]="'presentation'"
      >
        <ng-template bbDropdownLabel>
          <span class="dropdown-menu__label--full-width">Dropdown with full width button</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          <ul>
            @for (option of dropdownTemplateMenuOptions; track $index) {
              <li>
                <a rel="noopener noreferrer" class="dropdown-item" target="_blank" [href]="option.url">
                  {{ option.name }}
                </a>
              </li>
            }
          </ul>
        </ng-template>
      </bb-dropdown-menu-ui>
    </div>
    <div class="col-sm-6 bb-block bb-block--md">
      <div class="bb-block bb-block--md">
        <bb-dropdown-menu-ui
          icon="caret-down"
          buttonSize="sm"
          btnColor="primary"
          [bbDropdownToggleFullWidth]="true"
          [dropDownMenuRole]="'presentation'"
        >
          <ng-template bbDropdownLabel>
            <span>Dropdown with full width menu</span>
          </ng-template>
          <ng-template bbDropdownMenuItem>
            <ul>
              @for (option of dropdownTemplateMenuOptions; track $index) {
                <li>
                  <a rel="noopener noreferrer" class="dropdown-item" target="_blank" [href]="option.url">
                    {{ option.name }}
                  </a>
                </li>
              }
            </ul>
          </ng-template>
        </bb-dropdown-menu-ui>
      </div>
    </div>
    <div class="bb-block bb-block--md">
      <bb-dropdown-menu-ui
        icon="caret-down"
        btnColor="primary"
        buttonSize="sm"
        [fullWidth]="true"
        [bbDropdownToggleFullWidth]="true"
        [dropDownMenuRole]="'presentation'"
      >
        <ng-template bbDropdownLabel>
          <span class="dropdown-menu__label--full-width">Dropdown with full width button and full width menu</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          <ul>
            @for (option of dropdownTemplateMenuOptions; track $index) {
              <li>
                <a rel="noopener noreferrer" class="dropdown-item" target="_blank" [href]="option.url">
                  {{ option.name }}
                </a>
              </li>
            }
          </ul>
        </ng-template>
      </bb-dropdown-menu-ui>
    </div>
  </div>

  <div class="col-sm-6 bb-block bb-block--md">
    <div class="bb-block bb-block--md">
      <bb-dropdown-menu-ui
        icon="caret-down"
        btnColor="primary"
        buttonSize="md"
        [fullWidth]="true"
        [dropDownMenuRole]="'presentation'"
      >
        <ng-template bbDropdownLabel>
          <span class="dropdown-menu__label--full-width">Dropdown with full width button</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          <ul>
            @for (option of dropdownTemplateMenuOptions; track $index) {
              <li>
                <a rel="noopener noreferrer" class="dropdown-item" target="_blank" [href]="option.url">
                  {{ option.name }}
                </a>
              </li>
            }
          </ul>
        </ng-template>
      </bb-dropdown-menu-ui>
    </div>

    <div class="bb-block bb-block--md">
      <bb-dropdown-menu-ui
        icon="caret-down"
        class="btn-md"
        btnColor="primary"
        [bbDropdownToggleFullWidth]="true"
        [dropDownMenuRole]="'presentation'"
        iconSize="lg"
      >
        <ng-template bbDropdownLabel>
          <span>Dropdown with full width menu</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          <ul>
            @for (option of dropdownTemplateMenuOptions; track $index) {
              <li>
                <a rel="noopener noreferrer" class="dropdown-item" target="_blank" [href]="option.url">
                  {{ option.name }}
                </a>
              </li>
            }
          </ul>
        </ng-template>
      </bb-dropdown-menu-ui>
    </div>
    <div class="bb-block bb-block--md">
      <div class="bb-block bb-block--md">
        <bb-dropdown-menu-ui
          icon="caret-down"
          btnColor="primary"
          buttonSize="md"
          [fullWidth]="true"
          [bbDropdownToggleFullWidth]="true"
          [dropDownMenuRole]="'presentation'"
        >
          <ng-template bbDropdownLabel>
            <span class="dropdown-menu__label--full-width">Dropdown with full width button and full width menun</span>
          </ng-template>
          <ng-template bbDropdownMenuItem>
            <ul>
              @for (option of dropdownTemplateMenuOptions; track $index) {
                <li>
                  <a rel="noopener noreferrer" class="dropdown-item" target="_blank" [href]="option.url">
                    {{ option.name }}
                  </a>
                </li>
              }
            </ul>
          </ng-template>
        </bb-dropdown-menu-ui>
      </div>
    </div>
  </div>

  <div class="row bb-block bb-block--md">
    <div class="col-sm-6 bb-block bb-block--md">
      <h4>Split small button</h4>

      <div class="bb-block bb-block--md">
        <div class="btn-group btn-group-sm">
          <button bbButton buttonSize="sm">Open as</button>
          <bb-dropdown-menu-ui
            class="btn-group"
            icon="caret-down"
            container="body"
            aria-label="options"
            [options]="dropdownMenuOptions"
            [dropDownMenuRole]="'presentation'"
          >
          </bb-dropdown-menu-ui>
        </div>
      </div>
      <div class="bb-block bb-block--md">
        <div class="btn-group btn-group-sm">
          <button bbButton color="secondary">Open as</button>
          <bb-dropdown-menu-ui
            class="btn-group"
            container="body"
            icon="caret-down"
            btnColor="secondary"
            aria-label="options"
            [options]="dropdownMenuOptions"
            [dropDownMenuRole]="'presentation'"
          >
          </bb-dropdown-menu-ui>
        </div>
      </div>
      <div class="bb-block bb-block--md">
        <div class="btn-group btn-group-sm">
          <button bbButton>
            <bb-icon-ui name="quick-action" size="sm" cropped></bb-icon-ui>
            <span>Open as</span>
          </button>
          <bb-dropdown-menu-ui
            class="btn-group"
            icon="caret-down"
            container="body"
            aria-label="options"
            [options]="dropdownMenuOptions"
          >
          </bb-dropdown-menu-ui>
        </div>
      </div>
      <div>
        <div class="btn-group btn-group-sm">
          <button bbButton color="secondary">
            <bb-icon-ui name="quick-action" size="sm" cropped></bb-icon-ui>
            <span>Open as</span>
          </button>
          <bb-dropdown-menu-ui
            class="btn-group"
            icon="caret-down"
            container="body"
            aria-label="options"
            btnColor="secondary"
            [options]="dropdownMenuOptions"
            [dropDownMenuRole]="'presentation'"
          >
          </bb-dropdown-menu-ui>
        </div>
      </div>
    </div>
    <div class="col-sm-6 bb-block bb-block--md">
      <h4>Split medium button</h4>

      <div class="bb-block bb-block--md">
        <div class="btn-group">
          <button bbButton>Open as</button>
          <bb-dropdown-menu-ui
            class="btn-group"
            icon="caret-down"
            container="body"
            aria-label="options"
            iconSize="lg"
            [options]="dropdownMenuOptions"
            [dropDownMenuRole]="'presentation'"
          >
          </bb-dropdown-menu-ui>
        </div>
      </div>
      <div class="bb-block bb-block--md">
        <div class="btn-group">
          <button bbButton color="secondary">Open as</button>
          <bb-dropdown-menu-ui
            class="btn-group"
            icon="caret-down"
            container="body"
            btnColor="secondary"
            aria-label="options"
            iconSize="lg"
            [options]="dropdownMenuOptions"
            [dropDownMenuRole]="'presentation'"
          >
          </bb-dropdown-menu-ui>
        </div>
      </div>
      <div class="bb-block bb-block--md">
        <div class="btn-group">
          <button bbButton>
            <bb-icon-ui name="quick-action" size="md" cropped></bb-icon-ui>
            <span>Open as</span>
          </button>
          <bb-dropdown-menu-ui
            class="btn-group"
            icon="caret-down"
            container="body"
            aria-label="options"
            iconSize="lg"
            [options]="dropdownMenuOptions"
            [dropDownMenuRole]="'presentation'"
          >
          </bb-dropdown-menu-ui>
        </div>
      </div>
      <div>
        <div class="btn-group">
          <button bbButton color="secondary">
            <bb-icon-ui name="quick-action" color="primary" size="md" cropped></bb-icon-ui>
            <span>Open as</span>
          </button>
          <bb-dropdown-menu-ui
            class="btn-group"
            icon="caret-down"
            container="body"
            btnColor="secondary"
            aria-label="options"
            iconSize="lg"
            [options]="dropdownMenuOptions"
            [dropDownMenuRole]="'presentation'"
          >
          </bb-dropdown-menu-ui>
        </div>
      </div>
    </div>
  </div>
  <div class="row bb-block bb-block--md">
    <div class="col-sm-6 bb-block bb-block--md">
      <h4>Icon small button</h4>
      <bb-dropdown-menu-ui
        [options]="dropdownMenuOptions"
        icon="ellipsis-h"
        [btnCircle]="true"
        container="body"
        ariaLabel="dropdown with no label"
        iconSize="md"
        buttonSize="sm"
        (select)="onButtonDropdownSelect($event)"
      >
      </bb-dropdown-menu-ui>
    </div>
    <div class="col-sm-6 bb-block bb-block--md">
      <h4>Icon medium button</h4>
      <bb-dropdown-menu-ui
        [options]="dropdownMenuOptions"
        icon="ellipsis-h"
        [btnCircle]="true"
        container="body"
        ariaLabel="dropdown with no label"
        iconSize="lg"
        (select)="onButtonDropdownSelect($event)"
      >
      </bb-dropdown-menu-ui>
    </div>
  </div>
</div>
