<div class="bb-rich-text-editor">
  @if (label?.length) {
    <label class="bb-rich-text-editor__label form-label" [attr.id]="labelId" (bbKeyboardClick)="labelClick()">{{
      label
    }}</label>
  }
  <div
    [ngClass]="{ 'is-disabled': readonly || disabled }"
    class="bb-rich-text-editor__textbox form-control"
    role="textbox"
    [attr.aria-labelledby]="labelId"
    [attr.aria-disabled]="disabled"
  >
    <quill-editor
      class="bb-rich-text-editor__content"
      [placeholder]="placeholder"
      [readOnly]="readonly || disabled"
      (onEditorCreated)="configureToolbar()"
      (onEditorChanged)="changedEditor($event)"
      [attr.placeholder]="placeholder"
      [attr.readonly]="readonly"
      [attr.aria-label]="ariaLabel || label"
      customToolbarPosition="bottom"
      data-role="quill-editor"
    >
      <!-- Format toolbar -->

      <div quill-editor-toolbar (mousedown)="$event.preventDefault()">
        @for (groupKey of FORMAT_GROUPS_KEYS; track groupKey; let i = $index) {
          <ul class="ql-format-group list-unstyled bb-block--no-margin">
            @for (type of FORMAT_GROUPS[groupKey]; track $index) {
              @if (type.key; as typeKey) {
                @if (!type.hidden && actionList[typeKey]) {
                  <li
                    [bbTooltip]="type.translation"
                    container="body"
                    triggers="hover focus click"
                    [placement]="actionsTooltipPlacement"
                    class="ql-format-group_type"
                  >
                    <button
                      bbButton
                      [tabindex]="disabled || readonly ? -1 : 0"
                      [circle]="true"
                      color="link-dark"
                      [title]="type.translation"
                      [attr.data-role]="typeKey"
                      [attr.value]="type.value || null"
                      [attr.aria-label]="type.translation"
                      class="ql-{{ QuillEditorActionsAdapter[typeKey] }}"
                    ></button>
                  </li>
                }
              }
            }
          </ul>
        }
      </div>
    </quill-editor>
  </div>

  <!-- Text length counter -->

  @if (showCounter()) {
    <p
      class="bb-rich-text-editor__char-counter"
      data-role="char-counter"
      [ngClass]="{ 'text-danger': getMessageText().length > maxLength }"
    >
      <span dir="ltr">{{ getMessageText().length }} / {{ maxLength }}</span>
      @if (getMessageText().length > maxLength) {
        <span aria-live="polite" i18n="Limit exceeded text error@@bb-rich-text-editor-ui.limitExceeded"
          >, limit exceeded</span
        >
      }
    </p>
  }
</div>

<!-- Add/Edit link modal -->

<bb-modal-ui [isOpen]="modalOpened" (cancel)="closeModal()" (confirm)="onResult()">
  <form data-role="add-link-form">
    <bb-modal-header-ui
      title="Add link"
      i18n-title="Add link|Add link title@@bb-rich-text-editor-ui.rich-text-editor-link-modal.title"
    >
    </bb-modal-header-ui>
    <bb-modal-body-ui>
      <ng-template bbCustomModalBody>
        <label
          class="bb-label"
          [attr.for]="id + '_link-input'"
          i18n="Enter the link|Enter the link title@@bb-rich-text-editor-ui.rich-text-editor-link-modal.input-label"
        >
          Enter the URL Link
        </label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="selectionUrl"
          name="url"
          [attr.id]="id + '_link-input'"
          data-role="link-input"
        />
      </ng-template>
    </bb-modal-body-ui>
    <bb-modal-footer-ui #footerRef data-role="link-modal-footer">
      <ng-template bbCustomModalFooter>
        <div class="bb-button-bar">
          <button
            bbButton
            class="bb-button-bar__button"
            (click)="onResult()"
            type="submit"
            data-role="save-link"
            i18n="Save a link|Save a link button@@bb-rich-text-editor-ui.rich-text-editor-link-modal.save-button"
          >
            Save Link
          </button>
          <button
            bbButton
            class="bb-button-bar__button"
            (click)="closeModal()"
            color="secondary"
            data-role="cancel-link-saving"
            i18n="Cancel|Cancel button@@bb-rich-text-editor-ui.rich-text-editor-link-modal.cancel-button"
          >
            Cancel
          </button>
        </div>
      </ng-template>
    </bb-modal-footer-ui>
  </form>
</bb-modal-ui>
