import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { getBemModifiers, fadeSlideAnimation } from '@backbase/ui-ang/util';
import { BBNotificationConfig } from './notification.config';

export type NotificationType = 'success' | 'info' | 'warning' | 'error' | 'alert';

/**
 * @name NotificationComponent
 *
 * @description
 * Component that displays a notification.
 */
@Component({
  selector: 'bb-notification-ui',
  templateUrl: './notification.component.html',
  animations: [fadeSlideAnimation],
})
export class NotificationComponent implements OnInit, AfterViewInit {
  /**
   * The header for the notification.
   */
  @Input() header: TemplateRef<any> | string | undefined;
  /**
   * The message template for the notification. Defaults to an empty string.
   */
  @Input() message: TemplateRef<any> | string = '';
  /**
   * The primary action template for the notification.
   */
  @Input() primaryActionTemplate: TemplateRef<any> | undefined;
  /**
   * The secondary action template for the notification.
   */
  @Input() secondaryActionTemplate: TemplateRef<any> | undefined;
  /**
   * The type modifier for the notification. Defaults to 'info'
   *
   * This property will be used to add additional class names to the wrapper div of the notification
   * in the following format: `bb-notification--<<modifier>>`
   *
   */
  @Input() modifier: NotificationType = 'info';
  /**
   * Whether the notification is dismissible. Defaults to `true`.
   *
   *
   * If `true` then an additional class name is added to the wrapper div of the notification in the following format
   * `bb-notification--dismissible`
   */
  @Input() dismissible = true;
  /**
   * The text for the primary action button.
   * Will throw an error if unset and primary action set.
   */
  @Input() primaryActionText: string | undefined;
  /**
   * A callback function for the primary action. Required for buttons on notification.
   */
  @Input() primaryAction: Function | undefined;
  /**
   * The text for the secondary action button.
   * Will throw an error if unset and secondary action set.
   */
  @Input() secondaryActionText: string | undefined;
  /**
   * A callback function for the secondary action. Required for buttons on notification.
   */
  @Input() secondaryAction: Function | undefined;
  /**
   * The callback function for the manually close event.
   */
  @Input() closeAction: Function | undefined = undefined;
  /**
   * The callback function for the host click event.
   */
  @Input() hostAction: Function | undefined = undefined;
  /**
   * The context for notification header template.
   */
  @Input() headerContext: any;
  /**
   * The context for notification message template.
   */
  @Input() messageContext: any;
  /**
   * If `true`, notification opening and closing will be animated.
   * Animation is triggered only when the `.hide()` or `.show()` functions are called.
   */
  @Input() animation: boolean;
  /**
   * Whether the notification should focused when rendered.
   */
  @Input() autofocus = false;
  /**
   * Notification manually close event stream.
   */
  @Output() close = new EventEmitter();
  /**
   * Notification pause stream when focusIn/focusOut and mouseenter/mouseleave.
   * @internal
   */
  @Output() pauseNotification = new EventEmitter();

  @ViewChild('notificationContent') notificationContent!: ElementRef;
  @ViewChild('defaultNotificationHeaderTemplate', { static: true }) defaultHeaderTemplate: TemplateRef<any> | undefined;
  @ViewChild('defaultNotificationMessageTemplate', { static: true }) defaultMessageTemplate:
    | TemplateRef<any>
    | undefined;
  @ViewChild('defaultPrimaryActionsTemplate', { static: true }) defaultPrimaryActionsTemplate:
    | TemplateRef<any>
    | undefined;
  @ViewChild('defaultSecondaryActionsTemplate', { static: true }) defaultSecondaryActionsTemplate:
    | TemplateRef<any>
    | undefined;

  /**
   * Notification icon color.
   */
  iconColor: string | undefined;
  /**
   * Element that focused before notification is open, for back focus when notification is closed.
   */
  previousFocusedElement!: HTMLElement;
  private eventCounter = 0;
  protected bemBlock = 'bb-notification';
  protected bemModifiers = [() => (this.dismissible ? 'dismissible' : ''), () => this.modifier];

  get dynamicClassNames() {
    return getBemModifiers(this.bemBlock, this.bemModifiers);
  }

  @HostBinding('@.disabled') get noAnimation() {
    return !this.animation;
  }

  @HostBinding('@fadeSlideInOut') get animated() {
    return this.animation;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape':
        this.onNotificationClose();
        break;
      default:
        return;
    }
    event.preventDefault();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elem: ElementRef,
    config: BBNotificationConfig,
  ) {
    this.animation = config.animation;
  }

  ngOnInit() {
    if (this.hasPrimaryAction() && typeof this.primaryActionText === 'undefined') {
      throw new Error(
        `"primaryActionText" input is required when a primaryAction is set in "${this.constructor.name}"`,
      );
    }
    if (this.hasSecondaryAction() && typeof this.secondaryActionText === 'undefined') {
      throw new Error(
        `"secondaryActionText" input is required when a secondaryAction is set in "${this.constructor.name}"`,
      );
    }
    this.iconColor = this.modifier === 'error' ? 'danger' : this.modifier;
  }

  ngAfterViewInit(): void {
    if (document.activeElement instanceof HTMLElement) {
      this.previousFocusedElement = document.activeElement;
    }
  }

  get hostTabIndex() {
    return this.hostAction ? 0 : -1;
  }

  onNotificationClick() {
    if (this.hostAction) {
      this.hostAction();
      this.close.emit();
    }
  }

  onNotificationClose() {
    if (this.closeAction) {
      this.closeAction();
    }
    this.close.emit();
  }

  /**
   * @description
   * Handle mouseover or focusout.
   * Resume ttl for notification
   */
  @HostListener('mouseleave', ['$event'])
  @HostListener('focusout', ['$event'])
  private onFocusOut() {
    this.eventCounter--;
    if (this.eventCounter === 0) this.pauseNotification.emit(false);
  }

  /**
   * @description
   * Handle mouseover or click or focus
   * Pause the notification
   */
  @HostListener('mouseenter', ['$event'])
  @HostListener('focusin', ['$event'])
  private onFocusIn() {
    this.eventCounter++;
    this.pauseNotification.emit(true);
  }

  beforeDestroy() {
    const notificationList = this.document.querySelector('.bb-notification-list');
    const isNotificationListFocused = notificationList && notificationList.contains(this.document.activeElement);

    if (notificationList && isNotificationListFocused) {
      const notificationBtns = notificationList.querySelectorAll<HTMLElement>('.bb-notification__close');

      const currentIndex = Array.from(notificationList.children).findIndex(
        (listElement) => listElement === this.elem.nativeElement,
      );

      const nextElementToFocus =
        notificationBtns[currentIndex + 1] || notificationBtns[currentIndex - 1] || this.previousFocusedElement;

      if (nextElementToFocus) {
        nextElementToFocus.focus();
      }
    }
  }

  onPrimaryPress() {
    if (this.primaryAction) {
      this.primaryAction();
      this.onNotificationClose();
    }
  }

  onSecondaryPress() {
    if (this.secondaryAction) {
      this.secondaryAction();
      this.onNotificationClose();
    }
  }

  getHeaderTemplate(): TemplateRef<any> | undefined {
    if (this.header instanceof TemplateRef) {
      return this.header;
    }

    return this.defaultHeaderTemplate;
  }

  getMessageTemplate(): TemplateRef<any> | undefined {
    if (this.message instanceof TemplateRef) {
      return this.message;
    }

    return this.defaultMessageTemplate;
  }

  hasButtonRow(): boolean {
    return this.hasPrimaryAction() || this.hasSecondaryAction();
  }

  hasPrimaryAction(): boolean {
    return this.primaryAction !== undefined;
  }

  hasSecondaryAction(): boolean {
    return this.secondaryAction !== undefined;
  }
}
