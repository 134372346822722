import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '@backbase/ui-ang/icon';

import { NotificationComponent } from './notification.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';
import { FocusModule } from '@backbase/ui-ang/focus';

@NgModule({
  imports: [NgClass, NgTemplateOutlet, NgbAlertModule, IconModule, ButtonModule, KeyboardClickModule, FocusModule],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
})
export class NotificationModule {}
