<div class="mb-4">
  <h4>Default</h4>
  <bb-alert-ui [title]="'Default'" data-role="alert-default"></bb-alert-ui>
</div>

<div class="mb-4">
  <h4>Color variants</h4>
  @for (alert of colorVariants; track $index) {
    <bb-alert-ui
      [title]="alert.title"
      [modifier]="alert.modifier"
      [attr.data-role]="'alert-' + alert.modifier"
    ></bb-alert-ui>
  }
</div>

<div class="mb-4">
  <h4>Multiple lines</h4>
  <bb-alert-ui
    [title]="'Default'"
    modifier="info"
    message="Body of a sticky alert.  This alert type is placed above the site header."
    data-role="alert-multiple-lines"
  ></bb-alert-ui>
</div>

<div class="mb-4">
  <h4>Dismissible</h4>
  @if (!dismissibleAlerts[0].dismissed) {
    <bb-alert-ui
      [title]="'Dismissible alert'"
      modifier="info"
      [dismissible]="true"
      (close)="dismissibleAlerts[0].dismissed = true"
      data-role="alert-dismissible-single-line"
    ></bb-alert-ui>
  }
  @if (dismissibleAlerts[0].dismissed) {
    <bb-button-ui
      [id]="'restore-dismissable-alert-button'"
      color="secondary"
      (click)="dismissibleAlerts[0].dismissed = false"
      data-role="alert-dismissible-single-line-restore"
      >Restore the alert</bb-button-ui
    >
  }
  @if (!dismissibleAlerts[1].dismissed) {
    <bb-alert-ui
      [title]="'Dismissible alert'"
      modifier="info"
      message="With text"
      [dismissible]="true"
      (close)="dismissibleAlerts[1].dismissed = true"
      data-role="alert-dismissible-multi-line"
    ></bb-alert-ui>
  }
  @if (dismissibleAlerts[1].dismissed) {
    <bb-button-ui
      color="secondary"
      (click)="dismissibleAlerts[1].dismissed = false"
      data-role="alert-dismissible-multi-line-restore"
      >Restore the alert</bb-button-ui
    >
  }
</div>

<div class="mb-4">
  <h4>With buttons</h4>
  @for (alert of colorVariants; track $index) {
    <bb-alert-ui
      [title]="alert.title"
      [modifier]="alert.modifier"
      [dismissible]="true"
      [attr.data-role]="'alert-' + alert.modifier"
      message="Body of an alert."
      [primaryActionButtonText]="alert.primaryActionButtonText"
      [supportiveLinkText]="alert.supportiveLinkText"
      (primaryActionButtonClick)="alert?.primaryActionButtonClick()"
      (supportiveLinkClick)="alert?.supportiveLinkClick()"
    ></bb-alert-ui>
  }
</div>

<div class="mb-4">
  <h4>Custom message content template</h4>
  @for (alert of colorVariants; track $index) {
    <bb-alert-ui [title]="alert.title" [modifier]="alert.modifier" [attr.data-role]="'alert-' + alert.modifier">
      Lorem ipsum dolor sit amet consectetur <a href="javascript:void(0);" class="alert-link">click here</a> adipisicing
      elit. Dicta, corporis.
    </bb-alert-ui>
  }
</div>

<div class="mb-4">
  <h4>Dynamic dismissible alert</h4>
  @if (dynamicAlertDismissible) {
    <bb-alert-ui
      #dynamicAlertDismissibleRef
      [title]="'Dynamic alert'"
      data-role="alert-dynamic"
      [dismissible]="true"
      primaryActionButtonText="Primary action"
      (primaryActionButtonClick)="dynamicAlertDismissibleRef.closeAlert()"
      (close)="dynamicAlertDismissible = false"
    >
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, corporis.
    </bb-alert-ui>
  }
  <button bbButton color="primary" (click)="dynamicAlertDismissible = !dynamicAlertDismissible">
    Trigger dismissible
  </button>
</div>

<div class="mb-4">
  <h4>Dynamic non dismissible alert with primary button</h4>
  @if (dynamicAlertNonDismissibleWithButton) {
    <bb-alert-ui
      #dynamicAlertNonDismissibleWithButtonRef
      [title]="'Dynamic alert'"
      data-role="alert-dynamic"
      primaryActionButtonText="Primary action"
      (primaryActionButtonClick)="dynamicAlertNonDismissibleWithButtonRef.closeAlert()"
      (close)="dynamicAlertNonDismissibleWithButton = false"
    >
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, corporis.
    </bb-alert-ui>
  }
  <button
    bbButton
    color="primary"
    (click)="dynamicAlertNonDismissibleWithButton = !dynamicAlertNonDismissibleWithButton"
  >
    Trigger dismissible
  </button>
</div>
