<form [formGroup]="vForm">
  <div class="bb-block bb-block--md">
    <bb-switch-ui
      [id]="'defaultSwitch4'"
      [aria-label]="'Switch without visible label'"
      formControlName="defaultSwitch4"
    >
    </bb-switch-ui>
  </div>
  <div class="bb-block bb-block--md">
    <bb-switch-ui [id]="'defaultSwitch5'" [aria-label]="'Disabled switch'" formControlName="defaultSwitch5">
    </bb-switch-ui>
  </div>
  <div class="bb-block bb-block--md">
    <bb-switch-ui
      [labelPosition]="'right'"
      [label]="'Switch with visible label (on the right)'"
      [id]="'defaultSwitch3'"
      formControlName="defaultSwitch3"
    >
    </bb-switch-ui>
  </div>
  <div>
    <bb-switch-ui
      [label]="'Switch with visible label (on the left)'"
      [id]="'defaultSwitch2'"
      formControlName="defaultSwitch2"
    >
    </bb-switch-ui>
  </div>
</form>
