import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Optional,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { defaultSize, InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { TimepickerStringAdapter } from './input-timepicker.service';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { InputTimepickerConfig, INPUT_TIMEPICKER_CONFIG_TOKEN } from './input-timepicker.token-config';

const SIZE_MEDIUM = defaultSize;
const SIZE_SMALL = SIZE_MEDIUM / 2;
const SIZE_LARGE = SIZE_MEDIUM * 2;

const KEYWORD_SMALL = 'small';
const KEYWORD_MEDIUM = 'medium';
const KEYWORD_LARGE = 'large';

/**
 * @name InputTimepickerComponent
 *
 * @description
 * Component that displays a timepicker.
 *
 * ### Global configuration token
 * `INPUT_TIMEPICKER_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of  `InputTimepickerComponent` instances in your project.
 * 
 * *Note:* The token overwrites the default value only. If you have provided a value as a property on a specific component, the token is not be able to overwrite it.
 * 
 * The following properties can be overwritten using the token:
 *  - `clock`
 *  - `hourStep`
 *  - `max`
 *  - `min`
 *  - `minuteStep`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 * ```
    import { INPUT_TIMEPICKER_CONFIG_TOKEN } from '@backbase/ui-ang/input-timepicker';
    import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
    import { AppModule } from './app/app.module';
 
    const inputTimepickerConfig = {
      clock: '12h'
    }
 
    platformBrowserDynamic().bootstrapModule(AppModule, {
      providers: [{ provide: INPUT_TIMEPICKER_CONFIG_TOKEN, useValue: inputTimepickerConfig }]
    });
   ```
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 */
@Component({
  selector: 'bb-input-timepicker-ui',
  templateUrl: './input-timepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTimepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTimepickerComponent),
      multi: true,
    },
    { provide: NgbTimeAdapter, useClass: TimepickerStringAdapter },
  ],
})
export class InputTimepickerComponent extends InputBaseComponent implements Validator, AfterViewInit {
  /**
   * The number of hours to add/subtract when clicking hour spinners.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input('hourStep')
  get hourStep(): number {
    return this._hourStep ?? this.overrideConfiguration?.hourStep ?? 1;
  }

  set hourStep(value: number) {
    this._hourStep = value;
  }

  private _hourStep: InputTimepickerConfig['hourStep'];

  /**
   * Whether to display 12H or 24H mode.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input('clock')
  get clock(): '12h' | '24h' {
    return this._clock ?? this.overrideConfiguration?.clock ?? '24h';
  }

  set clock(value: '12h' | '24h') {
    this._clock = value;
  }

  private _clock: InputTimepickerConfig['clock'];

  /**
   * The number of minutes to add/subtract when clicking minute spinners.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input('minuteStep')
  get minuteStep(): number {
    return this._minuteStep ?? this.overrideConfiguration?.minuteStep ?? 1;
  }

  set minuteStep(value: number) {
    this._minuteStep = value;
  }

  private _minuteStep: InputTimepickerConfig['minuteStep'];

  /**
   * If true, the timepicker is readonly and can't be changed.
   */
  @Input() readonly = false;

  /**
   * If true, it is possible to select seconds.
   */
  @Input() seconds = false;

  /**
   * The number of seconds to add/subtract when clicking second spinners.
   */
  @Input() secondStep = 1;

  /**
   * If set, validate according to min time
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('min')
  get min(): InputTimepickerConfig['min'] {
    return this._min ?? this.overrideConfiguration?.min;
  }

  set min(value: InputTimepickerConfig['min']) {
    this._min = value;
  }

  private _min: InputTimepickerConfig['min'];

  /**
   * If set , validate according to max time
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input('max')
  get max(): InputTimepickerConfig['max'] {
    return this._max ?? this.overrideConfiguration?.max;
  }

  set max(value: InputTimepickerConfig['max']) {
    this._max = value;
  }

  private _max: InputTimepickerConfig['max'];

  /**
   * The autocomplete value of enclosed input control.
   */
  @Input() autocomplete: 'string' | undefined;

  @HostBinding('attr.class') cssClass = 'bb-input-timepicker';

  @ViewChild('timepicker') inputEl: ElementRef | undefined;

  get meridian() {
    return this.clock === '12h';
  }

  getKeywordBySize(size: number | string): string {
    if (Number(size) <= SIZE_SMALL) {
      return KEYWORD_SMALL;
    } else if (Number(size) >= SIZE_LARGE) {
      return KEYWORD_LARGE;
    } else {
      return KEYWORD_MEDIUM;
    }
  }

  getSizeByKeyword(keyword: any): number | undefined {
    const sizeKeywords: { [index: string]: number } = {
      [KEYWORD_SMALL]: SIZE_SMALL,
      [KEYWORD_MEDIUM]: SIZE_MEDIUM,
      [KEYWORD_LARGE]: SIZE_LARGE,
    };

    return typeof keyword === 'string' && sizeKeywords.hasOwnProperty(keyword) ? sizeKeywords[keyword] : undefined;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      /* eslint-disable-next-line  no-null/no-null */
      return null;
    }

    if (this.min && this.max) {
      const valid = this.min <= value && value <= this.max;

      return valid ? null : { minMaxTime: true };
    } else if (this.min) {
      return this.min <= value ? null : { minTime: true };
    } else if (this.max) {
      return value <= this.max ? null : { maxTime: true };
    }

    return null;
  }

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly domAttrService: DomAttributesService,
    private readonly elem: ElementRef,
    private readonly renderer2: Renderer2,
    @Optional()
    @Inject(INPUT_TIMEPICKER_CONFIG_TOKEN)
    private readonly overrideConfiguration: InputTimepickerConfig,
  ) {
    super(cd);
  }

  ngAfterViewInit(): void {
    if (!this.ariaLabel && this.inputEl) {
      this.domAttrService.moveAriaAttributes(this.elem.nativeElement, this.inputEl.nativeElement, this.renderer2);
    }
  }
}
