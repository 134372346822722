const A = 'A'.charCodeAt(0);
const Z = 'Z'.charCodeAt(0);

const PATTERNS: { [key: string]: string } = {
  A: 'A-Z',
  N: '0-9',
  C: '0-9A-Z',
};

// Stores bban validation regular expressions
const cachedBbanRegexp: { [key: string]: RegExp | undefined } = {};

// Generates BBAN format as a regular expression and cache it
const prepareBbanRegexp = (bbanFormat: string): RegExp | undefined => {
  const matchedString = bbanFormat.match(/[0-9]{2}[ANC]/g);
  if (!matchedString) return undefined;

  const pattern = matchedString.reduce((prevPattern, block) => {
    const quantity = parseInt(block.slice(0, 2), 10);
    const code = block[2];

    return `${prevPattern}[${PATTERNS[code]}]{${quantity}}`;
  }, '');
  cachedBbanRegexp[bbanFormat] = new RegExp(pattern);

  return cachedBbanRegexp[bbanFormat];
};

// Move the four initial characters to the end of the string
const rearrangeIban = (iban: string): string => `${iban.slice(4)}${iban.slice(0, 4)}`;

/**
 * Removes spaces
 *
 * name  compact
 * inner
 * param  {string} str A given string
 * return {string}     Compact string
 */
export const compact = (str: string): string => str.replace(/\s+/g, '');

/**
 * Generates a regular expression from encoded representation
 * It is grouped in blocks of 3 characters, first 2 represents the number
 * and the last character represents the type of character.
 * * A = Upper case alpha characters [A-Z]
 * * N = Numeric characters [0-9]
 * * C = Mixed case alphanumeric characters [0-9a-zA-Z]
 *
 * name bbanRegexp
 * inner
 * param  {string} bbanFormat Encoded regular expression
 * return {object}            Resulting regular expression
 */
export const bbanRegexp = (bbanFormat: string): RegExp | undefined =>
  cachedBbanRegexp[bbanFormat] || prepareBbanRegexp(bbanFormat);

/**
 * Replace each letter in the string with two digits,
 * thereby expanding the string, where A = 10, B = 11, ..., Z = 35
 *
 * name convertIban
 * inner
 * param {string} iban IBAN number
 * return {string} Number representation of the IBAN
 */
export const convertIban = (iban: string): string =>
  rearrangeIban(iban)
    .split('')
    .reduce((result, char) => {
      const code = char.charCodeAt(0);
      let num;
      if (code >= A && code <= Z) {
        num = String(code - A + 10);
      } else {
        num = char;
      }

      return `${result}${num}`;
    }, '');

/**
 * name alreadyExploded
 * type {function}
 * inner
 *
 * description
 * Checks if string is already split up
 *
 * param str
 * param symbol {string}
 * return {boolean}
 */
const alreadyExploded = (str: string, symbol: string): boolean | '' => str && str.split(symbol).length > 1;

/**
 * name explodeString
 * type {function}
 *
 * description
 * Splits the string up with spaces (or other symbol)
 * by groups of 4 (or other number).
 *
 * param str {string}
 * param symbol {string}
 * param groupLen {number}
 * return {string}
 */
export const explodeString = (str: string, symbol: string = ' ', groupLen: number = 4): string => {
  if (!str || alreadyExploded(str, symbol)) return str;

  const groups = Math.ceil(str.length / groupLen);
  const chunks = [];

  for (let i = 0; i < groups; i++) {
    chunks.push(str.substr(groupLen * i, groupLen));
  }

  return chunks.join(symbol);
};
