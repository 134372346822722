import { Component, Input, OnInit } from '@angular/core';

type IconSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

/**
 * @name IconComponent
 *
 * @description
 * Component that displays an icon.
 *
 * To see the full set of icons available to use see: <a href="/web-components/icon-set">Icons Set</a>.
 */
@Component({
  selector: 'bb-icon-ui',
  templateUrl: './icon.component.html',
})
export class IconComponent implements OnInit {
  private _cropped = false;
  private _flipOnRtl = false;

  /**
   * The name of the icon
   */
  @Input() name: string | undefined;

  /**
   * Whether to use white background and inverse icon colors
   */
  @Input() inverse = false;

  /**
   * The size of the icon represented as a size name contained in backbase-theme.  This is ignored
   * when the circle option is used. Defaults to `md`.
   */
  @Input() size: IconSize = 'md';

  /**
   * The color of the icon represented as a color name contained in backbase-theme.
   */
  @Input() color: string | undefined;

  /**
   * Whether the icon should be animated. Defaults to `false`.
   */
  @Input() animate = false;

  /**
   * Input that can be used to specify the `aria-label` attribute.
   */
  @Input('aria-label') ariaLabel: string | undefined;

  /**
   * Whether the icon should be cropped horizontally.
   */
  @Input()
  get cropped(): boolean | string {
    return this._cropped;
  }
  set cropped(value: boolean | string) {
    this._cropped = value != null && `${value}` !== 'false';
  }

  /**
   * Whether the icon should be flipped when the page direction is RTL (right-to-left).
   */
  @Input() set flipOnRtl(value: boolean) {
    this._flipOnRtl = value;
  }

  get flipOnRtl(): boolean {
    return this._flipOnRtl;
  }

  /**
   * Wether the icon has a background. If not set it will display just the icon without background.
   * Possible values are `circle` and `square`.
   */
  @Input() backgroundType: 'circle' | 'square' | undefined;

  get classNames(): string[] {
    return [
      this.name ? `bb-icon-${this.name}` : '',
      this.animate ? 'bb-icon--animate' : '',
      this.inverse ? 'bb-icon--inverse' : '',
      this.flipOnRtl ? 'bb-icon--rtl' : '',
      this.size ? `bb-icon--${this.size}` : 'bb-icon--md',
      this.backgroundType === 'circle' ? 'icon-circle' : '',
      this._cropped ? 'bb-icon--cropped' : '',
      ...this.getBackgroundTypeClass(),
    ].filter(Boolean);
  }

  ngOnInit() {
    if (typeof this.name === 'undefined') {
      throw new Error(`"name" input is required in "${this.constructor.name}"`);
    }
  }

  private getBackgroundTypeClass() {
    if (this.backgroundType) {
      return [
        'bb-icon__background',
        `bb-icon__background--${this.backgroundType}`,
        this.color ? `bb-icon__background--${this.color}` : 'bb-icon__background--primary',
      ];
    } else {
      return [this.color ? `bb-icon--${this.color}` : ''];
    }
  }
}
