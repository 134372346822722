import { Component } from '@angular/core';

interface Link {
  text: string;
  path: string;

  // workaround for routerLink which cannot redirect to
  // an external url without custom code
  isExternal?: boolean;
}

interface MainPanel {
  title: string;
  description: string;
  isDesignTokens?: boolean;
  isComponents?: boolean;
  link: Link;
}

interface NavigationPanel {
  icon?: string;
  iconColor?: string;
  imageSrc?: string;
  title: string;
  description?: string;
  links: Link[];
}

@Component({
  selector: 'bb-home-page',
  templateUrl: './home-page.component.html',
})
export class HomePageComponent {
  mainPanels: MainPanel[] = [
    {
      title: 'Design Tokens',
      description: 'The foundational design variables for flexible expression of your brand.',
      isDesignTokens: true,
      link: {
        text: 'Browse design tokens',
        path: '/principles/design-tokens',
      },
    },
    {
      title: 'Components',
      description: 'Reusable UI elements for fast and consistent development of digital banking products.',
      isComponents: true,
      link: {
        text: 'Browse components',
        path: '/web-components',
      },
    },
  ];

  navigationPanels: NavigationPanel[] = [
    {
      icon: 'palette',
      iconColor: 'warning',
      title: 'Theming',
      description: 'Apply your brand in one day',
      links: [
        {
          text: 'Creating a theme in Figma',
          path: '/theme/create-a-theme',
        },
        {
          text: 'Applying a theme on Web',
          path: '/theme/apply-a-theme-on-web',
        },
        {
          text: 'Appling a theme on iOS',
          path: '/theme/apply-a-theme-on-ios',
        },
        {
          text: 'Applying a theme on Android',
          path: '/theme/apply-a-theme-on-android',
        },
      ],
    },
    {
      icon: 'visibility',
      iconColor: 'success',
      title: 'Accessibility',
      links: [
        {
          text: 'Designer’s checklist',
          path: '/accessibility/designer-checklist',
        },
        {
          text: 'Developer’s checklist',
          path: '/accessibility/developer-checklist',
        },
      ],
    },
    {
      imageSrc: './assets/images/pages/home-github.svg',
      title: 'Release notes',
      description: 'Monthly new features and fixes',
      links: [
        {
          text: 'Android release notes',
          path: 'https://backbase.io/developers/documentation/release-notes/mobile-devkit/mobile-design-system/mds-android',
          isExternal: true,
        },
        {
          text: 'iOS release notes',
          path: 'https://backbase.io/developers/documentation/release-notes/mobile-devkit/mobile-design-system/mds-ios',
          isExternal: true,
        },
        {
          text: 'Web release notes',
          path: 'https://backbase.io/developers/angular/design-system/ui-ang/latest/additional-documentation/release-notes.html',
          isExternal: true,
        },
      ],
    },
  ];
}
