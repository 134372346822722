@if (label) {
  <label for="{{ id }}" class="form-label">{{ label }}</label>
}
<label [for]="countryCodeInputId" class="visually-hidden">
  Country Code {{ selectedCountryCode }} {{ selectedCountryName }}
</label>

<div class="bb-phone input-wrapper bb-force-ltr">
  @if (enableCountryCode) {
    <div
      class="visually-hidden"
      role="status"
      i18n="
        Selected country code|Screen reader description to be read out after a user has selected a country
        code@@bb-input-phone-ui.options.select"
    >
      Selected country code {{ selectedCountryCode }} {{ selectedCountryName }}.
    </div>
  }
  @if (enableCountryCode) {
    <ng-select
      #countryCodeSelector
      (change)="onChanges($event)"
      (close)="onClose()"
      (open)="onOpen()"
      [dropdownPosition]="dropdownPosition"
      [clearable]="clearable"
      [searchable]="searchable"
      [items]="countryList"
      [(ngModel)]="selectedCountryIsoCode"
      bindLabel="isoCode"
      bindValue="isoCode"
      [searchFn]="searchFunc"
      [readonly]="countryDropdowndisabled"
      class="bb-phone-country-code"
      [class.bb-phone-country-code__readonly]="countryDropdowndisabled"
      [class.bb-phone-country-code__disabled]="disabled"
      [labelForId]="countryCodeInputId"
      [notFoundText]="notFoundText"
    >
      <ng-template ng-label-tmp let-item="item">
        @if (!hideSelectedCountryFlag) {
          <i class="flag-icon" [ngClass]="item.isoCode | countryFlag" aria-hidden="true"> </i>
        }
        {{ item.countryCode }}
      </ng-template>
      <ng-template ng-header-tmp>
        <bb-search-box-ui
          (clear)="onClear()"
          (valueChange)="onFilterChange($event)"
          [showSearch]="true"
          [showClear]="true"
          [autofocus]="true"
          placeholder="Search"
          aria-label="Search Country"
          i18n-aria-label="
            Search Country|Accessible label for country code search box@@bb-input-phone-ui.search.aria-label"
          i18n-placeholder="Search|Search placeholder@@bb-input-phone-ui.search.placeholder"
          [aria-owns]="ngSelect?.isOpen ? ngSelect?.dropdownId : null"
          [aria-activedescendant]="ngSelect?.isOpen ? ngSelect?.itemsList?.markedItem?.htmlId : null"
        ></bb-search-box-ui>
        <div
          [id]="statusId"
          aria-live="polite"
          aria-atomic="true"
          class="visually-hidden"
          role="status"
          i18n="
            Number of options|Screen reader description of the number of options after filtering the
            combobox@@bb-input-phone-ui.options.count"
        >
          You are in a search box with {{ resultCount }} options. Type to search.
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="dropdown-option-item">
          <i class="flag-icon col-md-1" [ngClass]="item.isoCode | countryFlag" aria-hidden="true"> </i>
          <span class="col-md-3 pr-0">{{ item.countryCode }}</span
          >&nbsp;
          <span class="col-md-6 px-0 dropdown-option-item__country-name">{{ item.countryName }}</span>
          @if (this.ngSelect?.itemsList.items[index].selected) {
            <bb-icon-ui class="dropdown-option-item__check-icon col-md-2 px-0" name="check" color="color-neutral-dark">
            </bb-icon-ui>
          }
        </div>
      </ng-template>
    </ng-select>
  }

  <input
    id="{{ id }}"
    #inputPhone
    [attr.aria-autocomplete]="ariaAutocomplete"
    [attr.aria-controls]="ariaControls"
    [attr.aria-describedby]="!useDefaultAriaDescribedby ? ariaDescribedby : id + '-country-code'"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-expanded]="ariaExpanded"
    [attr.aria-activedescendant]="ariaActivedescendant"
    [attr.role]="role"
    [attr.aria-invalid]="ariaInvalid"
    [attr.aria-owns]="ariaOwns"
    [attr.aria-label]="(!label && ariaLabel) || null"
    [ngModel]="value"
    (ngModelChange)="onInputValueChange($event)"
    (input)="onTextChange()"
    (blur)="onBlur()"
    type="tel"
    inputmode="tel"
    class="form-control"
    (focus)="onFocus()"
    [disabled]="disabled"
    [readOnly]="readonly"
    [placeholder]="placeholder"
    [required]="required"
    [mask]="mask"
    [validation]="false"
    [attr.maxlength]="maxLength"
    [attr.minlength]="minLength"
    [pattern]="validationPattern"
    [attr.size]="size"
    [bbFocus]="autofocus"
    [ngClass]="inputClassName"
    [attr.autocomplete]="autocomplete"
  />
  @if (useDefaultAriaDescribedby) {
    <div id="{{ id }}-country-code" class="visually-hidden">
      <span
        i18n="
          Selected Country is|Selected Country is group By label
          @@bb-account-selector-ui.groupBy.selected-country-is.label"
        >Selected Country is </span
      >{{ selectedCountryName }}
      <span i18n="and code is|and code is group By label @@bb-account-selector-ui.groupBy.and-code-is.label"
        >and code is </span
      >{{ selectedCountryCode }}
    </div>
  }
</div>
