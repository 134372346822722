import {
  AfterViewInit,
  ContentChild,
  ContentChildren,
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
} from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { DropdownMenuToken, DropdownMenuOptionToken } from '@backbase/ui-ang/dropdown-menu';
import { TableDirective } from './table.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: 'th[bbSortable]',
})
export class TableSortableDirective implements AfterViewInit, OnDestroy {
  /**
   * The name of the column to be sorted
   */
  @Input() bbSortable?: string;
  /**
   * The current sort direction. Accepts one of the values of 'asc', 'desc' or ''
   */
  @Input() direction: SortDirection = '';

  private _sortDisabled = false;

  /**
   * Toggles the sorting functionality on the column.
   */
  @Input() set sortDisabled(value: boolean) {
    this._sortDisabled = value;
    if (this._sortDisabled) {
      this.renderer.removeClass(this.element.nativeElement, 'sortable');
      this.renderer.addClass(this.element.nativeElement, 'sortable--disabled');
      this.removeSortButton();
      this.removeSortArrow();
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'sortable--disabled');
      this.renderer.addClass(this.element.nativeElement, 'sortable');
      this.renderSortButton();
      this.appendSortArrow();
    }
  }
  /**
   * An event emitter for on sort actions.
   */
  @Output() sort = new EventEmitter<SortEvent>();

  @ContentChild(DropdownMenuToken) dropdown: DropdownMenuToken | undefined;
  @ContentChildren(DropdownMenuOptionToken, { descendants: true })
  bbMenuOptions!: QueryList<DropdownMenuOptionToken>;
  sortArrow: Element = this.createSortArrowElement();
  private buttonElement: HTMLElement | undefined;

  private destroyRef$ = inject(DestroyRef);

  constructor(
    private readonly parentTable: TableDirective,
    private readonly renderer: Renderer2,
    private readonly element: ElementRef,
  ) {
    this.parentTable.registerHeader(this);
  }

  @HostBinding('class.sortable') sortable = !this._sortDisabled;

  @HostBinding('class.asc')
  get asc(): boolean {
    return this.direction === 'asc';
  }

  @HostBinding('class.desc')
  get desc(): boolean {
    return this.direction === 'desc';
  }

  @HostListener('click')
  @HostListener('keyup.enter')
  @HostListener('keyup.space')
  rotate() {
    if (this.dropdownButtonEl) {
      return;
    }

    if (this._sortDisabled) {
      return;
    }
    this.emitSortEvent();

    if (this.buttonEl) {
      this.updateAriaSortAttribute();
    }
  }

  private get buttonEl() {
    return this.dropdown?.buttonEl?.nativeElement ?? this.buttonElement;
  }

  private get dropdownButtonEl(): HTMLElement | null {
    return this.dropdown?.buttonEl?.nativeElement;
  }

  private get thContentHtmlElement(): HTMLElement | null {
    return this.element.nativeElement.querySelector('.th-content');
  }

  ngAfterViewInit(): void {
    if (this.dropdownButtonEl) {
      this.presetDropdownButton();
    }

    this.renderSortButton();

    this.getMenuOptionSelectionChanges(this.bbMenuOptions).subscribe({
      next: (menuOption) => this.onMenuOptionSelect(menuOption),
    });

    this.appendSortArrow();
  }

  onMenuOptionSelect(menuOption: DropdownMenuOptionToken): void {
    if (this._sortDisabled) {
      return;
    }
    const columnValue = menuOption.bbDropdownMenuOption;
    const activeMenuOption = this.bbMenuOptions?.find((option) => option.active);
    // Reset currently active item
    if (activeMenuOption) {
      activeMenuOption.active = false;
    }
    // If same menu item is not clicked, then reset the direction for new menuitem
    if (activeMenuOption && columnValue !== activeMenuOption.bbDropdownMenuOption) {
      this.direction = '';
    }
    this.bbSortable = menuOption.bbDropdownMenuOption;
    this.emitSortEvent();
    this.markMenuOptionAsActive(menuOption);
    this.updateAriaSortAttribute();
  }

  /** Notify table with new sorting params */
  emitSortEvent(): void {
    if (this.bbSortable) {
      this.direction = rotate[this.direction];
      this.parentTable.onSort({ column: this.bbSortable, direction: this.direction });
    }
  }

  /** Combined stream of all of the bbTableMenuitem selectionChange events. */
  getMenuOptionSelectionChanges(menuOptions: QueryList<DropdownMenuOptionToken>) {
    return menuOptions.changes.pipe(
      switchMap((_) => merge(...menuOptions.map((menuOption) => menuOption.selectionChange))),
      takeUntilDestroyed(this.destroyRef$),
    );
  }

  /** mark the new menuitem as active only if sorting order is asc/desc */
  markMenuOptionAsActive(menuOption: DropdownMenuOptionToken): void {
    if (this.direction !== '') {
      menuOption.active = true;
    }
  }

  markAllMenuOptionAsInactive(): void {
    if (this.bbMenuOptions.length) {
      this.bbMenuOptions.forEach((menuOption) => (menuOption.active = false));
    }
  }

  updateAriaSortAttribute(): void {
    if (!this.buttonEl) {
      return;
    }

    if (!this.direction) {
      this.renderer.removeAttribute(this.buttonEl, 'aria-sort');
    } else {
      this.renderer.setAttribute(this.buttonEl, 'aria-sort', this.getAriaSortAttribute(this.direction));
    }
  }

  private getAriaSortAttribute(direction: SortDirection): string {
    return direction === 'asc' ? 'ascending' : 'descending';
  }

  /** Preset bb-dropdown button to add role for a11y and set full height and width of table column */
  private presetDropdownButton(): void {
    this.renderer.setStyle(this.dropdownButtonEl, 'height', '100%');
    this.renderer.setStyle(this.dropdownButtonEl, 'width', '100%');
  }

  private createSortArrowElement(): Element {
    const element = this.renderer.createElement('span');

    // backbase-theme with append an arrow icon via 'content'
    // this is necessary to prevent breaking changes while addressing a11y
    this.renderer.setAttribute(element, 'aria-hidden', 'true');

    return element;
  }

  private renderSortButton() {
    if (this.element.nativeElement.querySelector('button')) {
      return;
    }

    if (this._sortDisabled) {
      return;
    }

    const btn: HTMLButtonElement = this.renderer.createElement('button');
    btn.classList.add('th-button');

    const label = this.element.nativeElement.querySelector('.th-content')?.innerText;
    this.renderer.setAttribute(btn, 'aria-label', label ?? '');

    this.renderer.appendChild(this.element.nativeElement, btn);
    this.buttonElement = btn;
  }

  private removeSortButton() {
    if (this.buttonElement) {
      this.renderer.removeChild(this.element.nativeElement, this.buttonElement);
    }
  }

  private appendSortArrow() {
    if (this._sortDisabled || !this.thContentHtmlElement) {
      return;
    }
    this.renderer.appendChild(this.thContentHtmlElement, this.sortArrow);
  }

  private removeSortArrow() {
    this.renderer.removeChild(this.thContentHtmlElement, this.sortArrow);
  }

  ngOnDestroy(): void {
    this.parentTable.unRegisterHeader(this);
  }
}
