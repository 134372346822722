import { NgModule } from '@angular/core';
import { NgClass } from '@angular/common';
import { RichTextEditorComponent } from './rich-text-editor.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';
import { TooltipModule } from '@backbase/ui-ang/tooltip-directive';
import { ModalModule } from '@backbase/ui-ang/modal';
import { QuillModule } from 'ngx-quill';
import Counter from './helpers/counter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [
    NgClass,
    ButtonModule,
    BaseClassesModule,
    IconModule,
    KeyboardClickModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TooltipModule,
    QuillModule.forRoot({
      customModules: [
        {
          implementation: Counter,
          path: 'modules/counter',
        },
      ],
    }),
  ],
  declarations: [RichTextEditorComponent],
  exports: [RichTextEditorComponent],
})
export class RichTextEditorModule {}
