import { Inject, LOCALE_ID, Pipe, PipeTransform, Optional } from '@angular/core';
import {
  DATE_PIPE_DEFAULT_OPTIONS,
  formatDate,
  FormatWidth,
  getLocaleDateTimeFormat,
  DatePipeConfig as CoreDatePipeConfig,
} from '@angular/common';
import { DATE_PIPE_CONFIG, DatePipeConfig } from './date-format-config';

/**
 * @name bbDate
 *
 * @description
 * Pipe for formatting dates
 * <br><br>
 *
 * ### Locale token
 * `LOCALE_ID` enables you to globally set the same locale for all instances of `bbDatePipe` in your project.
 * Refer to https://angular.io/api/core/LOCALE_ID for more information.
 * 
 * * ### Default timezone and format option
 * `DATE_PIPE_DEFAULT_OPTIONS` enables you to globally set the default timezone and date format for all instances of `bbDatePipe` in your project.
 * Refer to https://angular.io/api/common/DATE_PIPE_DEFAULT_OPTIONS for more information.
 *
 * ### Format configuration token
 * `DATE_PIPE_FORMAT_CONFIG` enables to globally setup format for all instances of `bbDatePipe` in your project.
 * The following is an example of how to use the token:
 * ```
    import { DATE_PIPE_FORMAT_CONFIG, DateFormat } from '@backbase/ui-ang/date-pipe';
    import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
    import { AppModule } from './app/app.module';
 
    platformBrowserDynamic().bootstrapModule(AppModule, {
      providers: [{ provide: DATE_PIPE_FORMAT_CONFIG, useValue: DateFormat.short }]
    });
   ```
 *
 */
@Pipe({
  name: 'bbDate',
})
export class BbDatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Optional() @Inject(DATE_PIPE_CONFIG) private dateFormatConfig?: DatePipeConfig,
    @Inject(DATE_PIPE_DEFAULT_OPTIONS) @Optional() private defaultOptions?: CoreDatePipeConfig | null,
  ) {}

  /**
   * @name BbDatePipe#transform
   * @param value - the date that should be formatted
   * @param format - the format type for the date to be formatted
   * @param timezone - used for formatting the time
   * @param locale - locale to use for formatting
   *
   *
   * @description
   * Depending on format type, formats a date
   * Custom format is called with bbShort,
   * and formats to 'MMMM d, y at HH:mm',
   * otherwise uses the predifined angular formats
   *
   */
  transform(
    value: any,
    format = this.dateFormatConfig?.format ?? this.defaultOptions?.dateFormat ?? 'mediumDate',
    timezone: string | undefined = this.defaultOptions?.timezone,
    locale?: string,
  ): string | null {
    if (format === 'bbShort') {
      const localeFormat = getLocaleDateTimeFormat(this.locale, FormatWidth.Long);
      const dateFormatted = formatDate(value, 'MMMM d, y', this.locale, timezone);
      const timeFormatted = formatDate(value, 'HH:mm', this.locale, timezone);

      return localeFormat.replace(/'/g, '').replace('{1}', dateFormatted).replace('{0}', timeFormatted);
    }

    if (value == null || value === '' || value !== value) return null;

    try {
      return formatDate(value, format, locale || this.locale, timezone);
    } catch (error: any) {
      throw Error(`InvalidPipeArgument: '${error.message}' for pipe BbDatePipe`);
    }
  }
}
