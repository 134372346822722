@if (isOpen) {
  <ngb-alert
    [attr.aria-labelledby]="alertTitleId"
    [attr.aria-describedby]="alertDescriptionId"
    [attr.role]="dismissible ? 'alertdialog' : 'alert'"
    [attr.aria-live]="'assertive'"
    [dismissible]="false"
    [class.alert-dismissible]="dismissible"
    [type]="modifier | alertType"
    (close)="isOpen = false"
    (closed)="closeAlert()"
  >
    <div class="alert-body">
      <bb-icon-ui
        class="alert-icon"
        [name]="modifier | alertIcon"
        [color]="modifier | alertType"
        [attr.data-role]="'alert-icon'"
      ></bb-icon-ui>
      <div class="alert-content">
        <div class="alert-content__messages">
          <strong
            [id]="alertTitleId"
            [class.alert-heading]="title"
            role="heading"
            aria-level="2"
            [attr.data-role]="'alert-title'"
            >{{ title }}</strong
          >
          @if (message) {
            <div class="alert-description" [id]="alertDescriptionId" [attr.data-role]="'alert-message'">
              {{ message }}
            </div>
          } @else {
            @if (isContentProvided) {
              <div
                #contentWrapper
                [attr.data-role]="'alert-content'"
                [id]="alertDescriptionId"
                class="alert-description"
              >
                <ng-content></ng-content>
              </div>
            }
          }
        </div>
        @if (!!supportiveLinkText || !!primaryActionButtonText) {
          <div class="alert-actions" [class.alert-actions--align-bottom]="!!primaryActionButtonText">
            @if (!!primaryActionButtonText) {
              <button bbButton buttonSize="md" type="Button" color="neutral" (click)="primaryActionButtonClick.emit()">
                {{ primaryActionButtonText }}
              </button>
            }
            @if (!!supportiveLinkText) {
              <button
                bbButton
                buttonSize="sm"
                type="button"
                color="link-text"
                class="alert-actions__supportive-link"
                (click)="supportiveLinkClick.emit()"
              >
                {{ supportiveLinkText }}
              </button>
            }
          </div>
        }
      </div>
    </div>
    @if (dismissible) {
      <button
        bbButton
        class="alert-btn-close"
        color="link-dark"
        [circle]="true"
        (click)="closeAlert()"
        [attr.aria-label]="closeButtonAriaLabel"
      >
        <bb-icon-ui name="clear"></bb-icon-ui>
      </button>
    }
  </ngb-alert>
}
