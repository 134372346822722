import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { PaymentCardNumberFormat, SYNCHRONIZE_NUMBERS_MASKING } from './constants';
import { segment } from '@backbase/ui-ang/util';
import { AccountsDisplayingFormatService } from '@backbase/ui-ang/account-number-pipe';

/**
 * @name pad
 * @inner
 * @param value - the string to pad
 * @param length - the length to pad the string to
 * @param ch - the character to pad the string width
 * @param leftPad - whether to pad left (true) or pad right (false)
 *
 * @description
 * Pad a string to specified length with specified character
 */
function pad(value: string, length: number, ch: string = '0', leftPad = true) {
  const paddingSize = Math.max(length - value.length, 0);
  const padding = ch.repeat(paddingSize);

  return leftPad ? padding + value : value + padding;
}

/**
 * @name mask
 * @inner
 * @param value - the string to mask
 * @param indexStart - the index to start masking from
 * @param indexEnd - the index to end masking
 * @param maskChar - the character to mask with
 *
 * @description
 * Mask part of a string with specified character
 */
function mask(value: string, [indexStart, indexEnd]: [number, number], maskChar: string = '*') {
  let maskedString = '';

  if (indexEnd < 0) {
    const charsToShow = value.slice(indexEnd);
    maskedString = maskChar.repeat(value.length - charsToShow.length) + charsToShow;
  } else {
    const maskStr = maskChar.repeat(indexEnd - indexStart);
    maskedString = value.substring(0, indexStart) + maskStr + value.substring(indexEnd);
  }

  return maskedString;
}

/**
 * @name PaymentCardNumberPipe
 *
 * @description
 * A card number is the digit identifier found on payment cards, such as credit cards and debit cards. This card number prefix identifies the issuer of the card, and the digits that follow are used by the issuing organization, such as a bank, to identify the cardholder as a customer with their designated accounts.
 *
 * ### Numbers masking synchronization token
 * It is possible to enable numbers masking synchronization with backend.
 * If set to `true` FE does not apply any masking and relies on the backend masking behavior.
 * To enable this feaure the `SYNCHRONIZE_NUMBERS_MASKING` token should be provided. For example:
 * ```
    // other imports
    import { SYNCHRONIZE_NUMBERS_MASKING } from '@backbase/ui-ang/payment-card-number-pipe';
 
    @NgModule({
      //...
      providers:[
        //...
        {provide: SYNCHRONIZE_NUMBERS_MASKING, useValue: true}
      ]
    })
    export class AppModule {}
   ```
 */
@Pipe({
  name: 'paymentCardNumber',
})
export class PaymentCardNumberPipe implements PipeTransform {
  private readonly defaultFormat = this.accountsDisplayingFormatService.getDefaultFormat('cardNumber');

  /**
   * @name PaymentCardNumberPipe#constructor
   * @param accountsDisplayingFormatService - AccountsDisplayingFormatService
   * @param featuresService - FeaturesService
   *
   * @internal
   */
  constructor(
    private readonly accountsDisplayingFormatService: AccountsDisplayingFormatService,
    @Optional() @Inject(SYNCHRONIZE_NUMBERS_MASKING) private readonly synchronizeNumberMasking: boolean,
  ) {}

  /**
   * @name PaymentCardNumberPipe#transform
   * @param value - the string to transform
   * @param config - the formatting configuration
   * @param maskChar - the character to mask with, deprecated from 6.0.0
   *
   * @description
   * Formats a card number, mask it and split it into segments
   * - do not set config parameter to use with a global accountsFormat configuration
   * - use config parameter to apply a custom accountsFormat configuration
   */
  transform(value: string | number, config?: PaymentCardNumberFormat, maskChar: string = '•'): string {
    const displayingFormat = config ?? this.defaultFormat;
    let formattedNumber = value.toString();

    if (config && !this.synchronizeNumberMasking) {
      formattedNumber = pad(formattedNumber, config.length, maskChar);
      formattedNumber = mask(formattedNumber, config.maskRange, maskChar);
    }

    return `\u200e${segment(formattedNumber, displayingFormat.segments)}`;
  }
}
