<div
  class="bb-switch"
  (bbKeyboardClick)="!disabled && toggleSwitch()"
  [ngClass]="{
    'bb-switch--align-end': isLabelRight()
  }"
>
  @if (label) {
    <span [attr.id]="id + '__label'" class="bb-switch__label" data-role="switch-label" aria-hidden="true">
      {{ label }}
    </span>
  }

  <input
    [attr.id]="id"
    class="bb-input-checkbox__input"
    type="checkbox"
    [(ngModel)]="value"
    (change)="$event.stopPropagation() && !disabled && toggleSwitch()"
    [disabled]="disabled"
    [required]="required"
    [attr.role]="role ?? 'switch'"
    [attr.aria-describedby]="ariaDescribedby"
    [attr.aria-labelledby]="!label ? ariaLabelledby : id + '__label'"
    [attr.aria-expanded]="ariaExpanded"
    [attr.aria-invalid]="ariaInvalid"
    [attr.aria-owns]="ariaOwns"
    [attr.aria-activedescendant]="ariaActivedescendant"
    [attr.aria-label]="(!label && ariaLabel) || null"
    (focus)="focused = true"
    (blur)="onBlur($event)"
    [attr.data-role]="'checkbox-input'"
  />

  <span
    class="bb-switch__element"
    [ngClass]="{
      'bb-switch__element--checked': value,
      'bb-switch__element--disabled': disabled,
      'bb-switch__element--focused': focused
    }"
    (focus)="keyboardFocus = true"
    (blur)="keyboardFocus = false"
  >
    <span class="bb-switch__slider slider"></span>
  </span>
</div>
