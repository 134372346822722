<div class="modal-header" [attr.id]="headerId" [attr.data-role]="'modal-header'">
  @if (customModalHeader) {
    <ng-container *ngTemplateOutlet="customModalHeader?.templateRef; context: hostRef"> </ng-container>
  } @else {
    <h2 [innerText]="title"></h2>
  }
  @if (showClose) {
    <button
      bbButton
      color="link-dark"
      buttonSize="sm"
      [circle]="true"
      (click)="onClose()"
      [attr.aria-label]="closeButtonAriaLabel"
      class="modal-header-btn-close"
    >
      <bb-icon-ui name="clear"></bb-icon-ui>
    </button>
  }
</div>
