import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import {
  crossOutValueClass,
  highlightValueClass,
  srTextForNewVal,
  srTextForOldVal,
  valueDiffArrowLeftClass,
  valueDiffArrowRightClass,
  ValueDiffChangedModel,
  ValueDiffPosition,
} from './bb-value-diff.model';

@Directive({
  selector: '[bbValueDiff]',
})
export class ValueDiffDirective implements AfterViewInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  private readonly valueChanged$ = new Subject<ValueDiffChangedModel>();

  private readonly SROnlyOldEl = this.createA11ySpan(srTextForOldVal);
  private readonly SROnlyNewEl = this.createA11ySpan(srTextForNewVal);

  private _newValue = '';
  private _position = ValueDiffPosition.BEFORE;
  private arrow: HTMLElement | undefined;

  @HostBinding('class') classes = 'bb-value-diff';

  /**
   * New data with which we will compare content.
   */
  @Input('bbValueDiff')
  set newValue(value: string) {
    this._newValue = value.trim();
    this.onValueChanged(value);
  }

  get newValue(): string {
    return this._newValue;
  }

  /**
   * A position where differences will be added.
   * Default value: 'before'
   */
  @Input()
  set position(val: ValueDiffPosition) {
    this._position = val;
    this.onValueChanged(this._newValue);
  }

  get position(): ValueDiffPosition {
    return this._position;
  }

  /**
   * Text for screen reader to describe old value
   * Default value: 'Previous value:'
   */
  @Input('sr-only-old')
  set SROnlyOld(val: string) {
    this.renderer.setProperty(this.SROnlyOldEl, 'textContent', val);
  }

  /**
   * Text for screen reader to describe new value
   * Default value: 'Current value:'
   */
  @Input('sr-only-new')
  set SROnlyNew(val: string) {
    this.renderer.setProperty(this.SROnlyNewEl, 'textContent', val);
  }

  /**
   * Show arrow between old and new value
   * Default value: false
   */
  @Input() showArrow = false;

  private static isEqual(newData: string, initialEl: HTMLElement): boolean {
    return initialEl.innerText.trim() === newData.trim();
  }

  private get isPositionBefore(): boolean {
    return this._position === ValueDiffPosition.BEFORE;
  }

  private get parentEl(): HTMLElement {
    return this.elRef.nativeElement.parentElement;
  }

  constructor(
    private readonly elRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    const changedEl = this.elRef.nativeElement.cloneNode(true);

    this.valueChanged$
      .pipe(
        startWith({ newData: this._newValue, elRef: this.elRef }),
        filter(({ newData }: ValueDiffChangedModel) => !ValueDiffDirective.isEqual(newData, this.elRef.nativeElement)),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: ({ newData }: ValueDiffChangedModel) => {
          this.cdRef.detectChanges();
          this.renderer.setProperty(changedEl, 'textContent', newData);
          this.setupContainer(changedEl);
        },
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  private setupContainer(changedEl: HTMLElement) {
    const { nativeElement } = this.elRef;

    if (ValueDiffDirective.isEqual(changedEl.innerText, nativeElement)) {
      this.cleanUpContent(changedEl);
    } else {
      this.renderer.addClass(changedEl, highlightValueClass);
      this.renderer.addClass(nativeElement, crossOutValueClass);

      this.renderer.insertBefore(this.parentEl, changedEl, this.getSiblingEl());
      this.renderer.insertBefore(this.parentEl, this.SROnlyNewEl, changedEl);

      if (this.showArrow) {
        this.addElWithArrow();
      }

      this.renderer.insertBefore(this.parentEl, this.SROnlyOldEl, nativeElement);
    }
  }

  private cleanUpContent(changedEl: HTMLElement) {
    this.renderer.removeClass(changedEl, highlightValueClass);
    this.renderer.removeClass(this.elRef.nativeElement, crossOutValueClass);
    this.renderer.removeChild(this.parentEl, changedEl);
    this.renderer.removeChild(this.parentEl, this.SROnlyOldEl);
    this.renderer.removeChild(this.parentEl, this.SROnlyNewEl);

    if (this.arrow) {
      this.renderer.removeChild(this.parentEl, this.arrow);
    }
  }

  private createA11ySpan(content: string): HTMLElement {
    const SROnlySpan = this.renderer.createElement('span');
    this.renderer.addClass(SROnlySpan, 'visually-hidden');
    this.renderer.setProperty(SROnlySpan, 'textContent', content);

    return SROnlySpan;
  }

  private addElWithArrow() {
    if (!this.arrow) {
      const iconClass = this.isPositionBefore ? valueDiffArrowLeftClass : valueDiffArrowRightClass;

      this.arrow = this.renderer.createElement('i');
      this.renderer.addClass(this.arrow, iconClass);
    }

    this.renderer.insertBefore(this.parentEl, this.arrow, this.getSiblingEl());
  }

  private getSiblingEl(): HTMLElement {
    return this.isPositionBefore ? this.elRef.nativeElement : this.elRef.nativeElement.nextElementSibling;
  }

  private onValueChanged(newData: string) {
    this.valueChanged$.next({ newData });
  }
}
