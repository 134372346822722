import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { ButtonColor } from '@backbase/ui-ang/button';

/**
 * @name InputFileComponent
 *
 * @description
 * Component that displays a file input button.
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 */

@Component({
  selector: 'bb-input-file-ui',
  templateUrl: './input-file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true,
    },
  ],
})
export class InputFileComponent extends InputBaseComponent implements OnInit, Validator {
  /**
   * Accepted file formats. Defaults to any ('*');
   */
  @Input() accept = '*';

  /**
   * If set, validate according to the maximum file size in Megabytes.
   */
  @Input() maxSizeInMb: number | string | undefined;

  /**
   * Multiple/single file upload flag. Defaults to false;
   */
  @Input() multiple = false;

  /**
   * Button color. Defaults to 'link';
   */
  @Input() color: ButtonColor | string = 'link';

  /**
   * This Boolean attribute lets you specify that the button should have input focus when the page
   * loads, unless the user overrides it, for example by typing in a different control. Only one
   * form-associated element in a document can have this attribute specified. Defaults to false.
   */
  @Input() autofocus = false;

  /**
   * Button text. If set, change button text from default 'Attach file' to a custom one.
   */
  @Input() buttonText: string | undefined;

  /**
   * Size of the button. Defaults to 'md'.
   */
  @Input() buttonSize: 'sm' | 'md' = 'md';

  /**
   * Button icon name. Defaults to 'attachment';
   */
  @Input() iconName = 'attachment';

  constructor(protected readonly cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.maxSizeInMb && Math.sign(Number(this.maxSizeInMb)) !== 1) {
      this.maxSizeInMb = undefined;
      console.warn('[bb-input-file-ui]: invalid maximum file size, falling back to infinite');
    }
  }

  onValueChange(input: HTMLInputElement) {
    const files = input.files?.length && [...(input.files as any as File[])];

    if (Array.isArray(files)) {
      this.value = { files };
      this.onChange(this.value);
    }
  }

  validate(): ValidationErrors | null {
    let isMaxSizeExceeded = false;
    const value = this.value && (this.value as { files: File[] }).files;

    if (Array.isArray(value) && this.maxSizeInMb) {
      const maxSizeInBytes = Number(this.maxSizeInMb) * 1024 * 1024;
      isMaxSizeExceeded = value.some((file: File) => file.size > maxSizeInBytes);
    }

    return isMaxSizeExceeded ? { maxFileSizeExceeded: true } : null;
  }
}
