<ng-template #modalContent let-close="close">
  <div
    class="modal-content-container"
    (keydown)="trapKeyEvent($event)"
    (keyup)="trapKeyEvent($event)"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
    (bbElementViewInit)="onElementViewInit($event)"
    [attr.role]="role"
    [attr.aria-modal]="true"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
