import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CustomProductItemCurrentAccountDetailsFormatterDirective,
  CustomProductItemCurrentAccountMainSectionDirective,
  CustomProductItemCurrentAccountSecondarySectionDirective,
  CustomProductItemCurrentAccountTitleDirective,
  ProductItemCurrentAccountComponent,
} from './product-item-current-account.component';
import { AmountModule } from '@backbase/ui-ang/amount';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [NgClass, AmountModule, PaymentCardNumberModule, HighlightModule, IconModule],
  declarations: [
    ProductItemCurrentAccountComponent,
    CustomProductItemCurrentAccountDetailsFormatterDirective,
    CustomProductItemCurrentAccountMainSectionDirective,
    CustomProductItemCurrentAccountSecondarySectionDirective,
    CustomProductItemCurrentAccountTitleDirective,
  ],
  exports: [
    ProductItemCurrentAccountComponent,
    CustomProductItemCurrentAccountDetailsFormatterDirective,
    CustomProductItemCurrentAccountMainSectionDirective,
    CustomProductItemCurrentAccountSecondarySectionDirective,
    CustomProductItemCurrentAccountTitleDirective,
  ],
})
export class ProductItemCurrentAccountModule {}
