import { CountryList } from './input-phone.component';

export const extractCountryFromValue = (value: string, countryList?: CountryList) => {
  const candidateCountryCode = value.substring(0, 4);

  return countryList?.find((country) => candidateCountryCode.startsWith(country.countryCode));
};

export const stripCountryCodeFromValue = (value: string, countryCode?: string) =>
  countryCode ? value.replace(`${countryCode}`, '') : value;
