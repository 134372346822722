import { ChangeDetectionStrategy, Component, Input, OnInit, EventEmitter, Output, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PubSubService } from './pub-sub.service';
import { IProgressTrackerConfig } from './progress-tracker.model';
import {
  IProgressTrackerItemTemplateConfig,
  IProgressTrackerTemplateConfig,
} from './containers/progress-tracker-container.model';

/**
 * @name ProgressTrackerComponent
 *
 * @description
 * A tracker component that tracks multiple ongoing or asynchronous processes on a page.
 * It handles multiple ongoing process events so that you can trigger, cancel and/or destroy events on demand.
 * It is easily extendable as you can you can extend the templates by passing your customised
 * template as input bindings. Refer to examples for the implementation.
 *
 */
@Component({
  selector: 'bb-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressTrackerComponent implements OnInit {
  /**
   * The reference to a custom template for the Progress Tracker Container.
   *
   * Allows to completely override the way Progress Tracker is displayed.
   *
   */
  @Input() progressTrackerTemplate: TemplateRef<IProgressTrackerTemplateConfig> | undefined;

  /**
   * The reference to a custom template for the Progress Tracker Item.
   *
   * Allows to completely override the way Progress Tracker Item is displayed.
   *
   */
  @Input() progressTrackerItemTemplate: TemplateRef<IProgressTrackerItemTemplateConfig> | undefined;

  /**
   * The reference to a custom template for the Progress Tracker Item Header.
   *
   * Allows to completely override the way Progress Tracker Item heading is displayed.
   *
   */
  @Input() progressTrackerHeaderTemplate: TemplateRef<any> | undefined;

  private eventListSource = new BehaviorSubject<IProgressTrackerConfig[]>([]);
  eventListData: Observable<IProgressTrackerConfig[]> = this.eventListSource.asObservable();

  // change data to use getter and setter
  @Input()
  set eventList(value: IProgressTrackerConfig[]) {
    // set the latest value for eventListSource BehaviorSubject
    this.eventListSource.next(value);
  }

  @Output() cancel = new EventEmitter<string>();

  constructor(private pubSubService: PubSubService) {}

  ngOnInit(): void {
    this.pubSubService.data.subscribe({
      next: (response: string) => {
        this.cancel.emit(response);
      },
    });
  }
}
