import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CurrencyInputComponent } from './currency-input.component';
import { DropdownSingleSelectModule } from '@backbase/ui-ang/dropdown-single-select';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';

@NgModule({
  imports: [NgClass, ReactiveFormsModule, DropdownSingleSelectModule],
  declarations: [CurrencyInputComponent],
  exports: [
    CurrencyInputComponent,

    // exporting validation message as it can be injected into the component template
    InputValidationMessageModule,

    /**
     * @deprecated
     * Exporting `FormsModule` is deprecated
     **/
    FormsModule,
  ],
})
export class CurrencyInputModule {}
