import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';

@Directive({ selector: 'ng-template[bbCustomProductItemSavingsAccountTitle]' })
export class CustomProductItemSavingsAccountTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemSavingsAccountDetailsFormatter]' })
export class CustomProductItemSavingsAccountDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemSavingsAccountMainSection]' })
export class CustomProductItemSavingsAccountMainSectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemSavingsAccountComponent
 *
 * @description
 * A component that renders a styled-container or card for savings account.
 */
@Component({
  selector: 'bb-product-item-savings-account-ui',
  templateUrl: './product-item-savings-account.component.html',
})
export class ProductItemSavingsAccountComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemSavingsAccountTitleDirective, { static: true })
  customProductItemSavingsAccountTitle: CustomProductItemSavingsAccountTitleDirective | undefined;

  @ContentChild(CustomProductItemSavingsAccountDetailsFormatterDirective, { static: true })
  customProductItemSavingsAccountDetailsFormatter: CustomProductItemSavingsAccountDetailsFormatterDirective | undefined;

  @ContentChild(CustomProductItemSavingsAccountMainSectionDirective, { static: true })
  customProductItemSavingsAccountMainSection: CustomProductItemSavingsAccountMainSectionDirective | undefined;

  readonly hostRef: ProductItemSavingsAccountComponent = this;
  /**
   * The card currency.
   */
  @Input() currency: string | undefined;
  /**
   * The card amount value.
   */
  @Input() amount: number | undefined;
  /**
   * The card additional main section currency.
   */
  @Input() mainSectionCurrency: string | undefined;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionAmount: number | undefined;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionTitle: string | undefined;
}
