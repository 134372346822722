import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DefaultPaymentCardNumberFormat, PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';

/**
 * Payment card data.
 */
export interface PaymentCard {
  /**
   * Unformatted payment card number.
   */
  number?: number | string;
  /**
   * Payment card vendor.
   */
  vendor?: string;
  /**
   * Name to be displayed on the payment card.
   */
  name?: string;
  /**
   * Date the payment card expires.
   */
  expirationDate?: Date;
  /**
   * Name of the card.
   */
  cardName?: string;
  /**
   * Type of the card. Credit, Debit, etc.
   */
  type?: string;
  /**
   * Is the card virtual.
   */
  virtual?: boolean;
}

export enum PaymentCardSizes {
  SM = 'sm',
  MD = 'md',
}

/**
 * The prefix for the payment card class names.
 */
const classNamePrefix = 'bb-payment-card-';

/**
 * @name PaymentCardComponent
 *
 * @description
 * A component that displays card of any vendor that can be used as a form of payment.
 */
@Component({
  selector: 'bb-payment-card-ui',
  templateUrl: './payment-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardComponent {
  /**
   * Payment card data.
   */
  @Input() paymentCard?: PaymentCard;
  /**
   * Configuration of how the card number should be formatted.
   * Defaults to the following format:
   * ```
      {
        length: 16,
        maskRange: [0, 12],
        segments: 4,
      }
    ```
   *
   */
  @Input() paymentCardNumberFormat: PaymentCardNumberFormat = DefaultPaymentCardNumberFormat;

  /**
   * Defines the size of the card. Small cards show no content
   * Defaults to 'md'
   */
  @Input() size?: 'sm' | 'md' = PaymentCardSizes.MD;

  /**
   * configuration to pass a link or (base64, svg) image to be used as the background image of the card
   * Defaults to undefined
   */
  @Input() imageSrc: string | undefined;

  /**
   * Display Backbase logo on the card.
   */
  @Input() displayEmblem?: boolean = true;

  /**
   * Display card overlay in the card
   * Defaults to false
   */
  @Input() displayOverlay?: boolean = false;

  /**
   * Overlay status to be displayed on the card
   * Defaults to undefined
   */
  @Input() overlayText?: string;

  /**
   * Overlay icon to be displayed on the card
   * Possible values: 'access-time', 'lock', 'not-interested', 'remove-circle-outline', 'warning'
   * Defaults to undefined
   */
  @Input() overlayIcon?: 'access-time' | 'lock' | 'not-interested' | 'remove-circle-outline' | 'warning';

  /**
   * Local reference to the {@link PaymentCardSizes} enum.
   */
  protected PaymentCardSizes = PaymentCardSizes;

  get classNames(): string | undefined {
    if (this.paymentCard?.cardName) {
      const classNamePostfix = this.paymentCard.cardName
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, ' ')
        .trim()
        .replace(/\s/g, '-')
        .replace(/-{2,}/g, '-');

      return classNamePostfix ? `${classNamePrefix}${classNamePostfix}` : undefined;
    }

    return undefined;
  }

  get dynamicImageStyles(): Partial<CSSStyleDeclaration> {
    if (this.imageSrc) {
      return {
        backgroundImage: `url(${this.imageSrc})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      };
    }

    return {};
  }
}
