<div
  [attr.data-role]="'dropdown-panel'"
  class="bb-dropdown-panel"
  [placement]="position"
  [container]="container"
  (openChange)="changeState($event)"
  ngbDropdown
>
  <button
    ngbDropdownToggle
    type="button"
    aria-haspopup="listbox"
    [attr.data-role]="'dropdown-toggle'"
    [attr.required]="required ? true : undefined"
    [attr.aria-expanded]="isOpen | async"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    [attr.aria-activedescendant]="ariaActivedescendant"
    [attr.aria-invalid]="ariaInvalid"
    [attr.aria-owns]="ariaOwns"
    [attr.aria-label]="ariaLabel"
    [disabled]="disabled"
    [class]="'bb-dropdown-panel__dropdown-toggle ' + buttonClass"
    (focus)="onFocus()"
    (focusout)="onFocusOut($event)"
    id="{{ id }}"
  >
    <ng-container *ngTemplateOutlet="customDropdownToggle; context: { isOpen: isOpen | async }"></ng-container>
  </button>

  <div class="bb-dropdown-panel__dropdown" ngbDropdownMenu position="top" [attr.data-role]="'dropdown-panel-menu'">
    <ng-container *ngTemplateOutlet="customDropdownContent"></ng-container>
  </div>
</div>
