<time
  class="custom-day text-center bb-input-datepicker__single-day"
  [ngClass]="{
    'is-selected': selected,
    'text-body-secondary': isTextMuted
  }"
  [attr.data-role]="'calendar-date-item'"
  [dateTime]="format(date)"
>
  {{ date?.day }}
</time>
