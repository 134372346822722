import { NgModule } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { HeadingComponent } from './heading.component';
import { HeaderModule } from '@backbase/ui-ang/header';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { IconModule } from '@backbase/ui-ang/icon';
import { RouterModule } from '@angular/router';
import { HeadingDropdownComponent } from './components/heading-dropdown/heading-dropdown.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  declarations: [HeadingComponent, HeadingDropdownComponent],
  exports: [HeadingComponent],
  imports: [
    AsyncPipe,
    HeaderModule,
    DropdownMenuModule,
    IconModule,
    RouterModule,
    ButtonModule,
    KeyboardClickModule,
    DropdownMenuModule,
  ],
})
export class HeadingModule {}
