<div class="bb-char-counter bb-subtitle" [class.bb-char-counter--invalid]="!valid">
  <div aria-live="assertive" class="visually-hidden" aria-atomic="true" role="log">
    @switch (true) {
      @case (counter > maxLength - 4 && counter !== maxLength) {
        <div
          i18n="
            Above to reach Max Length Error|String is almost has 'maxLength' characters.
            @@bb-char-counter-ui.pre-max-length-warning"
        >
          You can type only {{ maxLength - counter }} more characters in this field.
        </div>
      }
      @case (counter === maxLength) {
        <div
          [attr.data-role]="'char-counter-error-message'"
          i18n="Max Length Error|String is more than 'maxLength' characters. @@bb-char-counter-ui.max-length-warning"
        >
          Limit reached. You can only use {{ maxLength }} characters in this field.
        </div>
      }
    }
  </div>

  @if (element) {
    <div [attr.data-role]="'char-counter-current-max'">{{ counter }}/{{ maxLength }}</div>
  }
</div>
