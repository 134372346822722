<div class="bb-input-inline-edit-ui">
  @if (isIdleMode) {
    <div class="bb-stack bb-input-inline-edit-ui__idle-wrapper">
      <ng-container
        *ngTemplateOutlet="inputInlineTemplate ? inputInlineTemplate : defaultInlineTemplate; context: this"
      >
      </ng-container>
      <ng-template #defaultInlineTemplate>
        <span class="bb-stack__item bb-stack__item--spacing-none" data-role="inline-edit-text">
          {{ inputText }}
        </span>
      </ng-template>
      @if (canEdit) {
        <button
          bbButton
          #editButton
          class="bb-stack__item bb-stack__item--no-shrink"
          (click)="onEdit($event)"
          [attr.aria-describedby]="ariaDescribedby"
          [attr.aria-label]="ariaLabelEdit"
          [attr.aria-labelledby]="ariaLabelledby"
          color="link-dark"
          [attr.data-role]="'inline-edit-btn'"
          [circle]="true"
          type="button"
          buttonSize="sm"
          [bbTooltip]="tooltipEdit"
          container="body"
          triggers="hover focus"
          placement="auto"
        >
          <bb-icon-ui class="bb-text-support" name="edit"></bb-icon-ui>
        </button>
      }
    </div>
  } @else {
    <div class="form-group">
      <form [formGroup]="vForm" (ngSubmit)="!hasError() && onAccept()">
        <bb-input-text-ui
          #inputTextElement
          [attr.data-role]="'inline-edit-input'"
          label="{{ label }}"
          formControlName="inputInline"
          [attr.aria-autocomplete]="ariaAutocomplete"
          [attr.aria-controls]="ariaControls"
          [attr.aria-label]="ariaLabelInput"
          [attr.aria-labelledby]="ariaLabelledby"
          [attr.aria-describedby]="
            idListAttr(
              ariaDescribedby,
              hasError() ? validationMessagesId : (showCharCounter || hint) && inlineEditDescriptionId
            )
          "
          [attr.aria-expanded]="ariaExpanded"
          [attr.aria-activedescendant]="ariaActivedescendant"
          [attr.role]="role"
          [attr.aria-invalid]="ariaInvalid"
          [attr.aria-owns]="ariaOwns"
          [required]="required"
          [readonly]="readonly"
          [maxLength]="maxLength"
          [minLength]="minLength"
          [autofocus]="true"
          [ngClass]="inputClassName"
          [attr.size]="size"
          (bbKeyboardClick)="$event.stopPropagation()"
          (blur)="onBlur($event)"
          id="{{ id }}"
          [autocomplete]="autocomplete"
          (ngModelChange)="onValueChange($event)"
        >
        </bb-input-text-ui>
        <div class="bb-stack bb-stack--align-top bb-stack--density-md">
          <div class="bb-stack__item bb-stack__item--fill">
            @if ((showCharCounter || hint) && !hasError()) {
              <div
                [attr.id]="inlineEditDescriptionId"
                class="bb-subtitle bb-text-support"
                data-role="inline-edit-description"
              >
                @if (showCharCounter && inputTextElement.inputEl?.nativeElement) {
                  <bb-char-counter-ui
                    class="bb-input-inline-edit-ui__char-counter"
                    [element]="inputTextElement.inputEl?.nativeElement"
                    [inputValue]="value"
                    [maxLength]="maxLength"
                    data-role="inline-edit-char-counter"
                  ></bb-char-counter-ui>
                }
                @if (showCharCounter && hint) {
                  <span class="bb-input-inline-edit-ui__description-seprator" aria-hidden="true">&#183;</span>
                }
                @if (hint) {
                  <span data-role="inline-edit-hint">{{ hint }}</span>
                }
              </div>
            }
            <bb-input-validation-message-ui [showErrors]="hasError()" [id]="validationMessagesId">
              @if (hasError('maxlength')) {
                <div
                  i18n="
                    Max Length Error|String is more than 'maxLength' characters @@input-inline-edit-ui.maxLengthError"
                >
                  Insert max {{ maxLength }} characters
                </div>
              }
              @if (hasError('minlength')) {
                <div
                  i18n="
                    Min Length Error|String is less than 'minLength' characters @@input-inline-edit-ui.minLengthError"
                >
                  Insert min {{ minLength }} characters
                </div>
              }
              @if (hasError('min')) {
                <div i18n="Min value Error|Number is less than 'minValue' @@input-inline-edit-ui.minValueError">
                  @if (!currency) {
                    Insert min {{ minValue }}
                  } @else {
                    Insert min
                    <bb-amount-ui [currency]="currency" [amount]="minValue" [trailingZeroes]="false"></bb-amount-ui>
                  }
                </div>
              }
              @if (hasError('max')) {
                <div i18n="Max value Error|Number is more than 'maxValue' @@input-inline-edit-ui.maxValueError">
                  @if (!currency) {
                    Insert max {{ maxValue }}
                  } @else {
                    Insert max
                    <bb-amount-ui [currency]="currency" [amount]="maxValue" [trailingZeroes]="false"></bb-amount-ui>
                  }
                </div>
              }
              @if (hasError('pattern')) {
                <div i18n="Format value error|Format value is invalid @@input-inline-edit-ui.formatValueError">
                  {{ patternErrorMessage }}
                </div>
              }
              @if (hasError('required')) {
                <div i18n="Required error|String is required @@input-inline-edit-ui.requiredError">
                  Field is required
                </div>
              }
            </bb-input-validation-message-ui>
          </div>
          <div class="bb-stack__item bb-stack__item--push-right flex-shrink-0">
            <div class="bb-button-bar">
              <button
                bbButton
                (click)="$event.stopPropagation()"
                color="success"
                [attr.data-role]="'inline-edit-accept'"
                type="submit"
                [circle]="true"
                [attr.aria-label]="ariaLabelAccept"
                buttonSize="sm"
                class="bb-button-bar__button bb-load-button"
                [bbTooltip]="tooltipAccept"
                container="body"
                triggers="hover focus"
                placement="auto"
              >
                @if (isLoading) {
                  <bb-loading-indicator-ui
                    class="bb-load-button__loading-indicator"
                    loaderSize="sm"
                  ></bb-loading-indicator-ui>
                } @else {
                  <bb-icon-ui name="check"></bb-icon-ui>
                }
              </button>
              <button
                bbButton
                type="reset"
                (click)="onCancel($event)"
                class="bb-button-bar__button"
                color="danger"
                [attr.data-role]="'inline-edit-cancel'"
                [circle]="true"
                [attr.aria-label]="ariaLabelCancel"
                buttonSize="sm"
                [bbTooltip]="tooltipCancel"
                container="body"
                triggers="hover focus"
                placement="auto"
              >
                <bb-icon-ui name="times"></bb-icon-ui>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  }
</div>
