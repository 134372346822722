import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';

@Directive({ selector: 'ng-template[bbCustomProductItemLoanTitle]' })
export class CustomProductItemLoanTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemLoanDetailsFormatter]' })
export class CustomProductItemLoanDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemLoanComponent
 *
 * @description
 * A component that renders a styled-container or card for loan.
 */
@Component({
  selector: 'bb-product-item-loan-ui',
  templateUrl: './product-item-loan.component.html',
})
export class ProductItemLoanComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemLoanTitleDirective, { static: true })
  customProductItemLoanTitle: CustomProductItemLoanTitleDirective | undefined;

  @ContentChild(CustomProductItemLoanDetailsFormatterDirective, { static: true })
  customProductItemLoanDetailsFormatter: CustomProductItemLoanDetailsFormatterDirective | undefined;

  readonly hostRef: ProductItemLoanComponent = this;
  /**
   * The card currency.
   */
  @Input() currency: string | undefined;
  /**
   * The card amount value.
   */
  @Input() amount: number | undefined;
}
